import React from "react";
import StudentList from "../components/StudentListing";
import { UserId, dinfo } from "../objects";
import Header from "../components/Header";
import IdleManager from "../IdleManager";
import { WithRouterProps, withRouter } from "../components/withRouter";


interface Params {
    identifier: string;
}

interface IState {
    org: string;
    orgName: string;
    needData: boolean;
    userId: string;

}

type Props = WithRouterProps<Params>;

class UserImpersonatePage extends React.Component<Props, IState> {

    public state: IState = {
        org: "",
        orgName: "",
        userId: "",
        needData: false,
    }

    public componentDidMount() {
        const { match } = this.props;
        this.setState({ needData: true, userId: match.params.identifier });
    }

    public callback = (org: string, orgName: string) => {
        console.log("callback", org, orgName);

        this.setState({ org: org, orgName: orgName });
    }



    public render() {

        return (

            <div className="w-[100%]">
                {this.state.needData &&
                <>
                    <Header org={this.state.org} callback={this.callback} userId={this.state.userId} isAdmin={false} />

                {UserId != null && UserId.length > 0 &&
                    <div className="p-5">
                        <StudentList org={this.state.org} orgName={this.state.orgName} userId={this.state.userId}  isAdmin={false} onNavigate={(showStudents: boolean) => {}} />
                    </div>
                }

                <IdleManager />
                </>
}
                {dinfo(this.state)}
            </div>


        );
    }

}


export default withRouter(UserImpersonatePage);
