
import * as React from 'react';

import { format, parse } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { dinfo } from '../objects';
import moment from 'moment';

interface ICalendarProps {
    date: string
    callback: ((date: string) => void);
}

interface ICalendarState {
    selected: Date | undefined;
}

function parseDate(str: string) {
    const timestamp = Date.parse(str);
    if (!isNaN(timestamp)) {
        return new Date(timestamp);
    }
    return new Date();
}



class Calendar extends React.Component<ICalendarProps, ICalendarState> {

    public state: ICalendarState = {
        selected: parseDate(this.props.date)
    }

    public onSelected = (d: Date | undefined) => {

        this.setState({
            selected: d
        });

        if (d != null && d instanceof Date) {
            var dd = format(d, 'yyyy-MM-dd');
            this.props.callback(dd);
        }
    }


    public render() {

        return (
            <React.Fragment>
                <div className="absolute z-10 border-[#DDDDDD] bg-white border-[1px] w-[400px] ml-[0px]">
                    {dinfo(this.props.date)}

                    {dinfo(new Date(this.props.date).toString())}

                    {dinfo(this.state.selected)}

                    <DayPicker
                        mode="single"
                        selected={this.state.selected ?? undefined}
                        onSelect={this.onSelected}
                        footer=''
                        defaultMonth={this.state.selected}
                    />

                </div>
            </React.Fragment>
        );
    }
};

//footer = <p>You picked {format(selected, 'yyyy-MM-dd')}.</p>;
export default Calendar;
