import axios from 'axios';
import saveAs from 'file-saver';
import * as React from 'react';
import { BASEAPI, SelectOption } from '../objects';

interface StudentListingFilterProps {
    org: string;
    search: string;
    filter: ((filter: string) => void);
}

interface StudentListingFilterState {

    org: string;
    search: string;

    loaded: boolean;
    pending: boolean;
    registered: boolean;
    started: boolean;

    //needData: boolean;
    options: SelectOption[];
}



class StudentListingFilter extends React.Component<StudentListingFilterProps, StudentListingFilterState> {

    public state: StudentListingFilterState = {

        org: this.props.org,
        search: this.props.search,

        loaded: false,
        pending: false,
        started: false,
        registered: false,
        options: [],

    };

    public componentDidMount() {
        this.GetData();
    }
    public componentDidUpdate() {
        if (this.state.org!=this.props.org || this.state.search!=this.props.search ) {

            this.setState({
                org: this.props.org,
                search: this.props.search,
            });
                    this.GetData();

        }

    }



    private GetData() {

        //if (this.state.needData) {
            if (this.props.org!=null && this.props.org.length>0) {
            axios.get<SelectOption[]>(BASEAPI + `GetStudentsCount?Organization=${this.props.org}&search=${this.props.search}`)
                .then(data => {
                    //if no errors
                    this.setState({
                        options: data.data,
                        //needData: false,
                    });

                });
            }
        //}
    }

    private pad(x: string, y: number) {

        const xLength = String(x).length;
        const yLength = String(y).length;

        const xpad = '\u00A0'.repeat(yLength - xLength) + x;

        console.log("pad", x, y, xLength, yLength);
        return xpad;

    }
    private GetCount(option: string) {

        if (this.state.options != null) {

            var t = this.state.options.reduce(function (a, b) {
                return a + Number(b.value);
            }, 0);

            var o = this.state.options.filter(x => x.text == option);
            if (o != null && o.length > 0 && o[0] != null) {
                return this.pad(o[0].value,t) + "/" + t;
            }
            else {
                return this.pad("0",t) +"/" + t;
            }
        }
        return "0/0";
    }

    private onCheck = (property: string) => {
        //event.preventDefault();

        console.log("onCheck");

        var state = this.state;

        switch (property) {
            case "loaded": state.loaded = !state.loaded; break;
            case "pending": state.pending = !state.pending; break;
            case "started": state.started = !state.started; break;
            case "registered": state.registered = !state.registered; break;

        }

        this.setState({ ...state });

        var filter = '';
        if (state.loaded) filter += 'loaded|';
        if (state.pending) filter += 'pending|';
        if (state.started) filter += 'started|';
        if (state.registered) filter += 'registered|';

        this.props.filter(filter)
    }

    private onClearAll = () => {

        console.log("onClearAll");

        this.setState({
            loaded: false,
            pending: false,
            started: false,
            registered: false,
        });

        this.props.filter('')
    }

    public render() {

        return (

            <button className="inline p-1 group" data-title={'Info'}>


                {!(this.state.pending || this.state.started || this.state.registered || this.state.loaded) &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="" viewBox="0 0 16 16">
                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                    </svg>
                }

                {(this.state.pending || this.state.started || this.state.registered || this.state.loaded) &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="" viewBox="0 0 16 16">
                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
                    </svg>
                }

                <div className="hidden group-hover:block absolute z-10 border-[#DDDDDD] border-[1px] w-[200px] ml-[-80px] ">




                    <div className="relative bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-0 sm:max-w-lg sm:w-full">
                        <div className="bg-white">

                            <div className="text-left p-2">

                                <div className='cursor-pointer p-1'>
                                    <span className='font-semibold'>Filters</span>

                                    <span className='float-right text-sm underline' onClick={() => this.onClearAll()}>Clear all</span>
                                </div>

                                <div className='cursor-pointer p-1'>
                                    Select Status
                                </div>

                                <div className='cursor-pointer p-1 hover:bg-[#DDDDDD]'>

                                    <label htmlFor={'loaded'} className="redcheckbox text-left"  >
                                        <input key={"loaded"} name={'loaded'} type="checkbox" checked={this.state.loaded} onChange={() => { return false; }}
                                        ></input>
                                        <span key={"loadedc1"} className="checkmark" onClick={() => { this.onCheck('loaded'); }}> </span>
                                        <span key={"loadedc2"} className={this.state.loaded ? "text-[#F88A2A]" : ""} onClick={() => { this.onCheck('loaded'); }}>
                                            <span className='mr-1 text-sm'>{this.GetCount('Loaded')}</span> Loaded
                                        </span>
                                    </label>

                                </div>


                                <div className='cursor-pointer p-1 hover:bg-[#DDDDDD]'>

                                    <label htmlFor={'pending'} className="redcheckbox text-left "  >
                                        <input key={"pending"} name={'pending'} type="checkbox" checked={this.state.pending} onChange={() => { return false; }}
                                        ></input>
                                        <span key={"pendingc1"} className="checkmark" onClick={() => { this.onCheck('pending'); }}> </span>
                                        <span key={"pendingc2"} className={this.state.pending ? "text-[#F88A2A]" : ""} onClick={() => { this.onCheck('pending'); }}>
                                            <span className='mr-1 text-sm'>{this.GetCount('Pending')}</span> Pending
                                        </span>
                                    </label>

                                </div>



                                <div className='cursor-pointer p-1 hover:bg-[#DDDDDD]'>

                                    <label htmlFor={'started'} className="redcheckbox text-left"  >
                                        <input key={"started"} name={'started'} type="checkbox" checked={this.state.started} onChange={() => { return false; }}
                                        ></input>
                                        <span key={"startedc"} className="checkmark" onClick={() => { this.onCheck('started'); }}> </span>
                                        <span key={"startedd"} className={this.state.started ? "text-[#F88A2A]" : ""} onClick={() => { this.onCheck('started'); }}>
                                            <span className='mr-1 text-sm'>{this.GetCount('Started')}</span> Started
                                        </span>

                                    </label>

                                </div>

                                <div className='cursor-pointer p-1 hover:bg-[#DDDDDD]'>

                                    <label htmlFor={'registered'} className="redcheckbox text-left "  >
                                        <input key={"registered"} name={'registered'} type="checkbox" checked={this.state.registered} onChange={() => { return false; }}
                                        ></input>
                                        <span key={"registeredc1"} className="checkmark" onClick={() => { this.onCheck('registered'); }}> </span>
                                        <span key={"registeredc2"} className={this.state.registered ? "text-[#F88A2A]" : ""} onClick={() => { this.onCheck('registered'); }}>
                                            <span className='mr-1 text-sm'>{this.GetCount('Registered')}</span> Registered
                                        </span>
                                    </label>

                                </div>




                            </div>
                        </div>

                    </div>


                </div>

            </button>
        );
    }









}
export default StudentListingFilter;