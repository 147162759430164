import axios from 'axios';
import * as React from 'react';
import { Address, AddressList, BASEAPI, dinfo } from '../objects';

interface AutocompleteProps {
    Url: string;
    Key: string;
    callback: ((address: string, city: string, zip: string) => void);
}

interface AutocompleteState {
    step: number;
    inputValue: string;
    suggestions: Address[];
}


class Autocomplete extends React.Component<AutocompleteProps, AutocompleteState> {

    public state: AutocompleteState = {
        step: 0,
        inputValue: "",
        suggestions: [],
    };

    //Great Russell St, London WC1B 3DG, United Kingdom
    //EH7 4JB
    //4/1 Dunedin Street


    fetchSuggestions = async (step: number, inputValue: string) => {

        var URL = (this.state.step == 0 ? BASEAPI + `GetAddressLookupList?addressString=${inputValue}` : BASEAPI + `GetAddressById?Id=${inputValue}`);

        if (inputValue != null && inputValue.length > 3) {
            axios.get<AddressList>(URL)
                .then(data => {
                    this.setState({
                        suggestions: data.data.items,

                    });
                });
        }
        else {
            this.setState({ suggestions: [] });
        }

    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        this.setState({ inputValue: inputValue, step:0 });
        this.fetchSuggestions(0, inputValue);
    };

    handleSelect = (suggestion: Address) => {
        console.log("handleSelect", this.state.step, suggestion);

        //if (this.state.step == 0) {
        if (suggestion.description!=null && suggestion.description.indexOf('Addresses')>-1) {
            this.fetchSuggestions(this.state.step + 1, suggestion.id);
            this.setState({ step: 1, suggestions: [] });

        }
        else {
            if (suggestion.description != null) {
                var s = suggestion.description.split(',');
                if (s != null && s.length == 2)
                    this.props.callback(suggestion.text, s[0], s[1]);
                this.setState({ step:0, suggestions: [] });//inputValue: "",
            }
        }
    };


    public render() {
        const { inputValue, suggestions } = this.state;
        return (
            <div>
                <label className='my-2 block'>Type A Part Of Address Or Postcode To Begin <span className="text-[#FF0000]">*</span></label>

                <input type="text" className="w-1/2 border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2" value={inputValue} onChange={this.handleChange} placeholder="Free text" />
                {suggestions != null && suggestions.length > 0 && (
                    <ul className="w-[50%] h-60 border border-gray-300 rounded-md bg-white absolute overflow-y-auto">
                        {suggestions.map((suggestion: Address) => (
                            <li className="px-5 py-3 border-b border-gray-200 text-stone-600 cursor-pointer hover:bg-slate-100 transition-colors"
                                key={suggestion.id} onClick={() => this.handleSelect(suggestion)}>
                                <div>{suggestion.text}</div>
                                <div>{suggestion.description}</div>
                            </li>
                        ))}
                    </ul>
                )}
    <div className='text-sm'>If your current home country is outside the United Kingdom, please enter your address manually</div>
                {dinfo(this.state)}

            </div>
        );
    }

}

export default Autocomplete;