import axios from 'axios';
import * as React from 'react';
import { Link } from 'react-router-dom'; //https://reactrouter.com/en/main/components/nav-link
import { BASEAPI, StudentLine, UserList, StudentUploadOptions, dinfo, todo, User } from '../objects';
import UserListingImpersonate from './UserListingImpersonate';

interface IUserListProps {
  org: string;
  orgName: string;
  onNavigate: ((showStudents: boolean) => void);
}

interface IState {
  org: string;
  needData: boolean;
  data: UserList;
  search: string;

  userId: string;

}



class UserListing extends React.Component<IUserListProps, IState> {



  public state: IState = {
    org: "",
    needData: true,
    data: {
      pageNumber: 1,
      totalPages: 0,
      isFirstPage: true,
      isLastPage: false,
      count: 0,
      users: [],
      totalCount: 0
    },
    search: '',
    userId: '',


  }


  public componentDidUpdate() {
    this.getDate();
  }


  public componentDidMount() {
    this.getDate();
  }

  public onImpersonate = async (id: string, name: string) => {
    console.log("onImpersonate", id);
    this.setState({
      userId: id,
    });

    var elem = (document.getElementById('Impersonate') as HTMLInputElement);
    if (elem != null) {
      elem.value = name;
    }

  }

  public onImpersonateCancel = async () => {
    this.setState({
      userId: '',

    });
  }



  public getDate() {
    if ((this.state.needData == true && this.props.org != null && this.props.org.length > 0) || (this.props.org != null && this.props.org.length > 0 && this.props.org != this.state.org)) {

      this.setState({
        org: this.props.org,
        needData: false
      });

      axios.get<UserList>(BASEAPI + `GetUsers?Organization=${this.props.org}&page=${this.state.data.pageNumber}&search=${this.state.search}`)
        .then(data => {
          //if no errors
          //data.data.json="{}";
          this.setState({
            data: data.data,
            needData: false
          });
        });
    }
  }



  private onPage = (nr: number) => {
    //event.preventDefault();


    console.log("onPage", nr);
    //event.dataTransfer.setData("text/plain", event.target.id);

    this.setState({
      data: { ...this.state.data, pageNumber: nr },
      needData: true
    });

  }


  private onPageDD = (event: any) => {
    //event.preventDefault();

    const nr: number = Number(event.target.value);

    console.log("onPageDD", event, event.target.value);
    //event.dataTransfer.setData("text/plain", event.target.id);

    this.setState({
      data: { ...this.state.data, pageNumber: nr },
      needData: true
    });


  }

  private onSearchChange = (event: any) => {
    //event.preventDefault();


    const value: string = event.target.value;

    console.log("onSearchChange", value);


    if (value === "" && this.state.search !== "") {
      this.setState({
        search: value,
        needData: true,
      });
    }
    else {
      this.setState({
        search: value,
      });
    }
  }

  private onSearch = () => {
    //event.preventDefault();

    console.log("onSearch",);

    this.setState({
      needData: true,
    });
  }




  public getStatus(status: string) {

    switch (status) {

      case "Loaded": return (
        <div className="w-[120px] border-[1px] rounded-md p-2 border-[#7771E2] text-[#7771E2]">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
          </svg>


          {status}</div>
      );

      case "Pending": return (
        <div className="w-[120px] border-[1px] rounded-md p-2 border-[#F88A2A] text-[#F88A2A]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>


          {status}</div>
      );
      case "Started": return (
        <div className="w-[120px]  border-[1px] rounded-md p-2 border-[#00BEDA] text-[#00BEDA]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

          {status}</div>
      );
      case "Registered": return (
        <div className="w-[120px] border-[1px] rounded-md p-2 border-[#336600] text-[#336600]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
          </svg>

          {status}</div>
      );


    }

  }

  public onKeyDown = async (event: any) => {
    console.log("onKeyDown", event.key);
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.setState({
        needData: true,
      });
    }
  }


  private arrayRange = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );


  public render() {

    var p: number[] = this.arrayRange(1, this.state.data.totalPages, 1);

    var pages: number[] = [];

    var currentPage = this.state.data.pageNumber;

    p.forEach(x => {
      if (x == 1 && Math.abs(currentPage - x) > 2) pages.push(x);
      if (x == 2 && Math.abs(currentPage - x) > 2) pages.push(-1);
      if (Math.abs(currentPage - x) <= 2) {
        pages.push(x);
      }
      if (x == p.length - 2 && Math.abs(currentPage - x) > 2) pages.push(-1);
      if (x > 1 && x == p.length - 1 && Math.abs(currentPage - x) > 2) pages.push(x);
    }
    );

    //console.log(pages);

    return (
      <React.Fragment>



        <div className='xl:flex '>
          <div className='w-full xl:w-2/5'>
            <span className='font-bold text-2xl mr-10 '>User List</span>
            <div className='inline-block w-[220px]'>
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">

                </div>
                <input type="search" id="default-search"
                  className="block p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search..." required
                  value={this.state.search}
                  onChange={this.onSearchChange}
                  onKeyDown={this.onKeyDown}

                />
                <button type="submit"
                  className="text-white absolute right-3 bottom-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={() => this.onSearch()}
                >
                  <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </button>
              </div>


            </div>
          </div>

          <div className='w-full xl:w-1/5 flex  text-center border-[1px] border-[#4f46D9] rounded-md mt-5 xl:mt-0'>
            <div onClick={()=> this.props.onNavigate(false)} className="w-1/2 p-2 bg-[#4f46D9] text-white  cursor-pointer h-[40px]">
              User
            </div>
            <div onClick={()=> this.props.onNavigate(true)}  className="w-1/2 p-2 h-[40px]">
              Students
            </div>
          </div>



        </div>
        <table className="w-[100%] text-left mt-5 text-sm">

          <thead className="bg-[#000000] text-[#FFFFFF]">
            <tr className="py-10 font-normal">

              <th key={"col2"} scope="col" className="px-2 pl-4 py-4 font-normal">NAME</th>
              <th key={"col3"} scope="col" className="px-2 py-4 font-normal">EMAIL ADDRESS</th>
              {/*<th key={"col4"} scope="col" className="px-2 py-4 font-normal">ROLE TYPE</th>*/}
              <th key={"colA"} scope="col" className="px-2 py-4 font-normal text-right pr-4">ACTION</th>
            </tr>
          </thead>

          {this.state.data != null && this.state.data.users != null && this.state.data.users.length > 0 &&
            <tbody>



              {this.state.data.users.map((record: User, index2: number) =>
                <tr key={"row" + index2} className="border-b even:bg-gray-50">


                  <td key={"cell2"} className="px-2 pl-4 py-4 text-ellipsis font-semibold">{record.name}</td>
                  <td key={"cell3"} className="px-2 py-4 text-ellipsis">{record.email}                  </td>
                  {/*<td key={"cell4"} className="px-2 py-4 text-ellipsis">
                    {record.isATO && <span>ATO</span>}
                    {record.isAdmin && <span>HR</span>}
                  </td>
              */}
                  <td key={"cellA"} className="px-2 py-4 text-ellipsis underline text-[#4f46D9] font-bold cursor-pointer text-right pr-4" onClick={() => this.onImpersonate(record.userId, record.name)}>


                    Impersonate


                  </td>

                </tr>
              )}

              <tr>


                <td colSpan={99} className='text-left p-4 '>




                  <span key="" className='float-left'>
                    Page <select
                      className="inline p-2 mx-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={this.state.data.pageNumber}
                      onChange={this.onPageDD}
                    >

                      {p.map((page: number, index3: number) =>
                        <option key={"o" + index3} value={page}>{page}</option>
                      )}
                    </select>
                    of {this.state.data.totalPages}
                  </span>


                  <span className="float-right p-2 ">

                    {!this.state.data.isFirstPage && this.state.data.pageNumber > 1 &&
                      <span key={"pageFirst"} className="px-2 cursor-pointer border-[1px] rounded-md py-2 px-3 mx-1 border-[#DDDDDD]" onClick={() => this.onPage(1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                        </svg>

                      </span>}

                    {!this.state.data.isFirstPage && this.state.data.pageNumber > 1 &&
                      <span key={"pagePrevious"} className="px-2 cursor-pointer border-[1px] rounded-md py-2 px-3 mx-1 border-[#DDDDDD]" onClick={() => this.onPage(this.state.data.pageNumber - 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>

                      </span>}

                    {this.state.data.totalPages > 0 &&
                      pages.map((nr: number, index: number) =>
                        <span key={'o' + index}>
                          {nr > -1 &&
                            <span key={"page" + index} className={"px-2 cursor-pointer border-[1px]  rounded-md py-2 px-3 mx-1" + ((nr == this.state.data.pageNumber) ? " border-blue-700 bg-blue-700 text-white " : " border-[#DDDDDD]")}
                              onClick={() => this.onPage(nr)}>{nr}
                            </span>}
                          {nr === -1 && <span key={"page" + index} className="px-2 " >...</span>}
                        </span>
                      )
                    }

                    {!this.state.data.isLastPage && this.state.data.pageNumber < this.state.data.totalPages &&

                      <span key={"pageNext"} className="px-2 cursor-pointer border-[1px] rounded-md py-2 px-3 mx-1 border-[#DDDDDD]" onClick={() => this.onPage(this.state.data.pageNumber + 1)} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline" >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>

                      </span>
                    }

                    {!this.state.data.isLastPage && this.state.data.pageNumber < this.state.data.totalPages &&

                      <span key={"pageLast"} className="px-2 cursor-pointer border-[1px] rounded-md py-2 px-3 mx-1 border-[#DDDDDD]" onClick={() => this.onPage(this.state.data.totalPages)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                        </svg>

                      </span>
                    }

                  </span>


                </td>
              </tr>

            </tbody>
          }

          {!(this.state.data != null && this.state.data.users != null && this.state.data.users.length > 0) &&
            <tbody>
              <tr>
                <td colSpan={10} className='text-center p-10'>No user found</td>
              </tr>
            </tbody>
          }


        </table>

        {this.state.userId != null && this.state.userId.length > 0 &&
          <UserListingImpersonate userId={this.state.userId} cancel={this.onImpersonateCancel} />
        }

        {dinfo(this.state)}
      </React.Fragment>
    );
  }
};


export default UserListing;
