import axios from 'axios';
import * as React from 'react';
import { Link } from 'react-router-dom'; //https://reactrouter.com/en/main/components/nav-link
import { ALL, BASEAPI, StudentLine, StudentList, StudentUploadOptions, UserId, dinfo, todo } from '../objects';
import StudentListingMenu from './StudentListingMenu';
import StudentListingFilter from './StudentListingFilter';
import StudentListingDownload from './StudentListingDownload';
import StudentListingUpload from './StudentListingUpload';
import StudentListingDelete from './StudentListingDelete';
import StudentListingMail from './StudentListingMail';
import StudentListingDetails from './StudentListingDetails';
import Calendar from './Calendar';
import AdminStudentListingDelete from './AdminStudentListingDelete';

interface IStudentListProps {
  org: string;
  orgName: string;
  userId: string;

  isAdmin: boolean;
  onNavigate: ((showStudents: boolean) => void);
}

interface IState {
  org: string;

  needData: boolean;
  data: StudentList;
  search: string;
  filter: string;
  allChecked: boolean;

  deleteid: number;
  mailids: number[];
  viewid: number;
  editid: number;
  deleteids: number[];

  options: StudentUploadOptions | undefined;

}



class StudentListing extends React.Component<IStudentListProps, IState> {



  public state: IState = {
    org: "",
    needData: true,
    data: {
      pageNumber: 1,
      totalPages: 0,
      isFirstPage: true,
      isLastPage: false,
      count: 0,
      students: [],
      totalCount: 0
    },
    allChecked: false,
    search: '',
    filter: '',
    deleteid: -1,
    mailids: [],
    viewid: -1,
    editid: -1,
    deleteids: [],
    options: undefined
  }


  public componentDidUpdate() {
    this.getDate();
  }


  public componentDidMount() {
    this.getDate();
  }

  public getDate() {
    if ((this.state.needData == true && this.props.org != null && this.props.org.length > 0) || (this.props.org != null && this.props.org.length > 0 && this.props.org != this.state.org)) {

      this.setState({
        org: this.props.org,
        needData: false
      });

      axios.get<StudentList>(BASEAPI + `GetStudents?Organization=${this.props.org}&page=${this.state.data.pageNumber}&search=${this.state.search}&filter=${this.state.filter}`)
        .then(data => {
          //if no errors
          //data.data.json="{}";
          this.setState({
            data: data.data,
            needData: false
          });
        });
    }
  }


  private onCheckAll = (event: any) => {
    //event.preventDefault();

    console.log("onCheckAll",);

    const value: boolean = !this.state.allChecked;


    var students = this.state.data.students.map(x => { x.selected = value; return x; });


    this.setState({
      data: { ...this.state.data, students: students },
      allChecked: value
    });
  }



  private onCheck = (id: number) => {
    //event.preventDefault();

    console.log("onCheck", this.state.data.students.length);

    var students = this.state.data.students.map(x => { if (x.id === id) x.selected = !x.selected; return x; });

    const all: number = students.filter(x => x.selected === true).length; console.log("all", all);

    var allChecked = this.state.allChecked;
    if (all == students.length) allChecked = true; else allChecked = false;

    this.setState({
      data: { ...this.state.data, students: students },
      allChecked: allChecked
    });
  }


  private onPage = (nr: number) => {
    //event.preventDefault();


    console.log("onPage", nr);
    //event.dataTransfer.setData("text/plain", event.target.id);

    this.setState({
      data: { ...this.state.data, pageNumber: nr },
      needData: true
    });

  }


  private onPageDD = (event: any) => {
    //event.preventDefault();

    const nr: number = Number(event.target.value);

    console.log("onPageDD", event, event.target.value);
    //event.dataTransfer.setData("text/plain", event.target.id);

    this.setState({
      data: { ...this.state.data, pageNumber: nr },
      needData: true
    });


  }

  private onSearchChange = (event: any) => {
    //event.preventDefault();


    const value: string = event.target.value;

    console.log("onSearchChange", value);


    if (value === "" && this.state.search !== "") {
      this.setState({
        search: value,
        needData: true,
      });
    }
    else {
      this.setState({
        search: value,
      });
    }
  }

  private onSearch = () => {
    //event.preventDefault();

    console.log("onSearch",);

    this.setState({
      needData: true,
    });
  }

  public onKeyDown = async (event: any) => {
    console.log("onKeyDown", event.key);
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.setState({
        needData: true,
      });
    }
  }


  public onFilter = async (filter: string) => {
    this.setState({
      filter: filter,
      needData: true,
    });

  }

  public onDelete = async (id: number) => {
    console.log("onDelete", id);
    this.setState({
      deleteid: id,
    });

  }

  public onDeleteDo = async () => {

    this.setState({
      deleteid: -1,
      needData: true,
    });

  }
  public onDeleteCancel = async () => {
    this.setState({
      deleteid: -1,
    });
  }

  public onMail = async (id: number) => {
    console.log("onMail", id);
    this.setState({
      mailids: [id],
    });

  }


  public onMailCancel = async () => {

    console.log("onMailCancel");
    //unselect everybody
    var students = this.state.data.students.map(x => { x.selected = false; return x; });

    this.setState({
      mailids: [],
      data: { ...this.state.data, students: students },
      allChecked: false
    });

  }

  public onMailComplete = async () => {
    console.log("onMailComplete");

    //unselect everybody
    var students = this.state.data.students.map(x => { x.selected = false; return x; });

    this.setState({
      //mailids: [],
      //data: { ...this.state.data, students: students },
      //allChecked: false,
      needData: true
    });

  }



  public onAdminDelete = async (id: number) => {
    console.log("onAdminDelete", id);
    this.setState({
      deleteids: [id],
    });

  }


  public onAdminDeleteCancel = async () => {

    console.log("onAdminDeleteCancel");
    //unselect everybody
    var students = this.state.data.students.map(x => { x.selected = false; return x; });

    this.setState({
      deleteids: [],
      data: { ...this.state.data, students: students },
      allChecked: false
    });

  }

  public onAdminDeleteComplete = async () => {
    console.log("onMailCoonAdminDeleteCompleteplete");

    //unselect everybody
    var students = this.state.data.students.map(x => { x.selected = false; return x; });

    this.setState({
      needData: true
    });

  }

  public onView = async (id: number) => {
    console.log("onView", id);
    this.setState({
      viewid: id,
    });

  }


  public onViewCancel = async () => {
    this.setState({
      viewid: -1,
    });
  }

  public onEdit = async (id: number) => {
    console.log("onEdit", id);
    this.setState({
      editid: id,
    });

  }

  public onEditDo = async () => {

    this.setState({
      editid: -1,
      needData: true,
    });

  }
  public onEditCancel = async () => {
    this.setState({
      editid: -1,
      needData: true,
    });
  }


  public getEmail(id: number) {

    var Selected: StudentLine[] = this.state.data.students.filter(x => x.id === id);
    if (Selected != null && Selected.length > 0 && Selected[0] != null) {
      return Selected[0].email;
    }

    return "";

  }

  public Reminder = async () => {


    var Selected = this.state.data.students.filter(x => x.selected == true).map(x => x.id);

    this.setState({
      mailids: Selected,
    });


  }

  public AdminDelete = async () => {


    var Selected = this.state.data.students.filter(x => x.selected == true).map(x => x.id);

    this.setState({
      deleteids: Selected,
    });


  }


  public getStatus(status: string) {

    switch (status) {

      case "Loaded": return (
        <div className="w-[120px] border-[1px] rounded-md p-2 border-[#7771E2] text-[#7771E2]">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
          </svg>


          {status}</div>
      );

      case "Pending": return (
        <div className="w-[120px] border-[1px] rounded-md p-2 border-[#F88A2A] text-[#F88A2A]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>


          {status}</div>
      );
      case "Started": return (
        <div className="w-[120px]  border-[1px] rounded-md p-2 border-[#00BEDA] text-[#00BEDA]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

          {status}</div>
      );
      case "Registered": return (
        <div className="w-[120px] border-[1px] rounded-md p-2 border-[#336600] text-[#336600]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline mr-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
          </svg>

          {status}</div>
      );
      case "Approved": return (
        <div className="w-[120px] border-[1px] rounded-md p-2 border-[#198754] text-[#198754]">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-6 h-6 inline mr-1" viewBox="0 0 16 16">
            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
          </svg>

          {status}</div>
      );


    }

  }


  private arrayRange = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );


  public getOptions = () => {
    axios.get<StudentUploadOptions>(BASEAPI + `GetOptions`)
      .then(data => {
        this.setState({
          options: data.data

        });
      });
  };

  /*
  public onSelectOne=(id:number) => {

    var d = this.state.data.students.map(x => { if (x.id == id) { x.selected = !x.selected; } return x; });
    console.log("Select2", d);

    //selectAll: false
    this.setState({
      data: {...this.state.data, students: d}
    });



  }

  public onSelectALL=() => {


  }
  */

  public render() {

    var p: number[] = this.arrayRange(1, this.state.data.totalPages, 1);

    var pages: number[] = [];

    var currentPage = this.state.data.pageNumber;

    p.forEach(x => {
      if (x == 1 && Math.abs(currentPage - x) > 2) pages.push(x);
      if (x == 2 && Math.abs(currentPage - x) > 2) pages.push(-1);
      if (Math.abs(currentPage - x) <= 2) {
        pages.push(x);
      }
      if (x == p.length - 2 && Math.abs(currentPage - x) > 2) pages.push(-1);
      if (x > 1 && x == p.length - 1 && Math.abs(currentPage - x) > 2) pages.push(x);
    }
    );

    //console.log(pages);




    return (



      <React.Fragment>

        <div className='xl:flex'>
          <div className='w-full xl:w-2/5 flex'>
            <span className='w-full xl:w-1/4 font-bold text-2xl mr-10 text-ellipsis overflow-hidden ... '>Student List</span>
            <div className='w-full xl:w-2/4 inline-block whitespace-nowrap '>
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
              <div className="relative">

                <input type="search" id="default-search"
                  className="w-full block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  h-[40px]"
                  placeholder="Search..." required
                  value={this.state.search}
                  onChange={this.onSearchChange}
                  onKeyDown={this.onKeyDown}

                />
                <button type="submit"
                  className="text-white absolute right-0 bottom-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 h-[40px]"
                  onClick={() => this.onSearch()}
                >
                  <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </button>
              </div>


            </div>
          </div>




          {this.props.isAdmin &&
            <>
              <div className='w-full xl:w-1/5 flex text-center border-[1px] border-[#4f46D9] rounded-md  mt-5 xl:mt-0'>
                <div onClick={() => this.props.onNavigate(false)} className="w-full xl:w-1/2 p-2  h-[40px]">
                  User
                </div>
                <div onClick={() => this.props.onNavigate(true)} className="w-full xl:w-1/2 p-2 bg-[#4f46D9] text-white  cursor-pointer h-[40px]">
                  Students
                </div>
              </div>


              <div className='w-full xl:w-2/5 text-right mt-2  mt-5 xl:mt-0' >

              <div className="whitespace-nowrap inline max-w-[2.5rem] border-[1px] rounded-md p-2 border-[#000000] cursor-pointer ml-4 h-[40px] text-center">
                  <StudentListingFilter filter={this.onFilter} org={this.props.org} search={this.state.search} />
                </div>

                <div className="whitespace-nowrap inline max-w-[8rem] text-ellipsis overflow-hidden ... border-[1px] rounded-md p-2 border-[#000000] bg-[#FFFFFF] text-[#000000] cursor-pointer ml-4 h-[40px] text-center">
                <StudentListingDownload OrgId={this.props.org} OrgName={this.props.orgName} blank={false} />
              </div>

                <div className="inline w-[6rem] border-[1px] rounded-md p-2 border-[#000000] cursor-pointer ml-4 h-[40px]" onClick={() => this.AdminDelete()}>
                  Delete
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" strokeWidth={1.5} stroke="currentColor" className="inline ml-2 w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>

                </div>
              </div>
            </>
          }


          {!this.props.isAdmin &&
            <div className='w-full xl:w-3/5 flex justify-end mt-5 xl:mt-0'>
              <div className="whitespace-nowrap inline max-w-[2.5rem] border-[1px] rounded-md p-2 border-[#000000] cursor-pointer ml-4 h-[40px] text-center">
                <StudentListingFilter filter={this.onFilter} org={this.props.org} search={this.state.search} />
              </div>
              <div className="whitespace-nowrap inline w-[30%] max-w-[16rem] text-ellipsis overflow-hidden ... border-[1px] rounded-md p-2 border-[#000000] cursor-pointer ml-4 h-[40px] text-center" onClick={() => this.Reminder()}>
                Send registration link email
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="inline ml-2" viewBox="0 0 16 16">
                  <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z" />
                </svg>
              </div>

              <div className="whitespace-nowrap inline max-w-[8rem] text-ellipsis overflow-hidden ... border-[1px] rounded-md p-2 border-[#000000] bg-[#FFFFFF] text-[#000000] cursor-pointer ml-4 h-[40px] text-center">
                <StudentListingDownload OrgId={this.props.org} OrgName={this.props.orgName} blank={false} />
              </div>

              <div className="whitespace-nowrap inline max-w-[19rem] text-ellipsis overflow-hidden ... border-[1px] rounded-md p-2 border-[#F88A2A] bg-[#F88A2A] text-white cursor-pointer ml-4 h-[40px] text-center">
                <StudentListingDownload OrgId={this.props.org} OrgName={this.props.orgName} blank={true} />
              </div>

              <div className="whitespace-nowrap inline max-w-[10rem] text-ellipsis overflow-hidden ... border-[1px] rounded-md p-2 border-[#4f46D9] bg-[#4f46D9] text-white cursor-pointer ml-4 h-[40px] text-center">
                <StudentListingUpload OrgId={this.props.org} OrgName={this.props.orgName} callback={() => { this.setState({ needData: true }); }} />
              </div>
            </div>

          }




        </div>


        <table className="w-full text-left mt-5 text-sm ">

          <thead className="bg-[#000000] text-[#FFFFFF]">
            <tr className="py-10 font-normal">
              <th key={"col1"} scope="col" className="px-2 py-4 ">
                <label htmlFor={'all'} className="redcheckbox text-center"  >
                  <input key={"all"} name={'all'} type="checkbox" checked={this.state.allChecked || false} onChange={() => { return false; }}
                  ></input>
                  <span key={"allc"} className="checkmark" onClick={this.onCheckAll}></span>
                </label>
              </th>
              <th key={"col2"} scope="col" className="px-2 py-4 font-normal">NAME</th>
              <th key={"col3"} scope="col" className="px-2 py-4 font-normal">EMAIL ADDRESS</th>
              {this.props.org == ALL &&
              <th key={"colO"} scope="col" className="px-2 py-4 font-normal">ORGANISATION</th>
            }
              <th key={"col4"} scope="col" className="px-2 py-4 font-normal">STUDENT TYPE</th>
              <th key={"col5"} scope="col" className="px-2 py-4 font-normal">TRAINING CONTRACT START DATE</th>
              <th key={"col6"} scope="col" className="px-2 py-4 font-normal">STATUS</th>
              <th key={"col7"} scope="col" className="px-2 py-4 font-normal">TRAINING PROVIDER</th>
              <th key={"col8"} scope="col" className="px-2 py-4 font-normal">QUALIFICATION</th>
              <th key={"col9"} scope="col" className="px-2 py-4 font-normal">PREFERRED CLASS LOCATION</th>
              <th key={"colA"} scope="col" className="px-2 py-4 font-normal">ACTIONS</th>
            </tr>
          </thead>

          {this.state.data != null && this.state.data.students != null && this.state.data.students.length > 0 &&
            <tbody>



              {this.state.data.students.map((record: StudentLine, index2: number) =>
                <tr key={"row" + index2} className="border-b even:bg-gray-50">

                  <td key={"cell1"} className="px-2 py-4 text-ellipsis text-center">
                    {dinfo(record.id)}
                    <label key={"lall"} htmlFor={'all'} className="redcheckbox" >
                      <input key={"all"} name={'all'} type="checkbox" checked={record.selected || false} onChange={() => { return false; }}
                      ></input>
                      <span key={"allc"} className="checkmark" onClick={() => this.onCheck(record.id)}></span>
                    </label>

                  </td>
                  <td key={"cell2"} className="px-2 py-4 font-semibold">{record.firstName} {record.lastName}</td>
                  <td key={"cell3"} className="px-2 py-4 break-words">
                    {record.email}

                    {window.location.href != null && window.location.href.indexOf("3000") > 0 &&
                      <Link to={"/form/" + record.identifier} className="underline ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="silver" className="w-4 h-4 inline">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                        </svg>
                      </Link>
                    }
                  </td>
                  {this.props.org == ALL &&
                  <td key={"colO"} className="px-2 py-4 text-ellipsis">{record.organisationName}</td>
  }
                  <td key={"cell4"} className="px-2 py-4 text-ellipsis">{record.studentType}</td>
                  <td key={"cell5"} className="px-2 py-4 text-ellipsis">{record.startDate}</td>
                  <td key={"cell6"} className="px-2 py-4 text-ellipsis">{this.getStatus(record.status)}

                  </td>
                  <td key={"cell7"} className="px-2 py-4 text-ellipsis">{record.provider}</td>
                  <td key={"cell8"} className="px-2 py-4 text-ellipsis">{record.qualification}</td>
                  <td key={"cell9"} className="px-2 py-4 text-ellipsis">{record.prefferedClassLocation}</td>
                  <td key={"cellA"} className="px-2 py-4 text-ellipsis">

                    <StudentListingMenu id={record.id} status={record.status} mail={this.onMail} delete={this.onDelete} view={this.onView} edit={this.onEdit} />

                  </td>

                </tr>
              )}

              <tr>


                <td colSpan={99} className='text-left p-4 '>




                  <span key="" className='float-left'>
                    Page <select
                      className="inline p-2 mx-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={this.state.data.pageNumber}
                      onChange={this.onPageDD}
                    >

                      {p.map((page: number, index3: number) =>
                        <option key={"o" + index3} value={page}>{page}</option>
                      )}
                    </select>
                    of {this.state.data.totalPages}
                  </span>


                  <span className="float-right p-2 ">

                    {!this.state.data.isFirstPage && this.state.data.pageNumber > 1 &&
                      <span key={"pageFirst"} className="px-2 cursor-pointer border-[1px] rounded-md py-2 px-3 mx-1 border-[#DDDDDD]" onClick={() => this.onPage(1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                        </svg>

                      </span>}

                    {!this.state.data.isFirstPage && this.state.data.pageNumber > 1 &&
                      <span key={"pagePrevious"} className="px-2 cursor-pointer border-[1px] rounded-md py-2 px-3 mx-1 border-[#DDDDDD]" onClick={() => this.onPage(this.state.data.pageNumber - 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>

                      </span>}

                    {this.state.data.totalPages > 0 &&
                      pages.map((nr: number, index: number) =>
                        <span key={'o' + index}>
                          {nr > -1 &&
                            <span key={"page" + index} className={"px-2 cursor-pointer border-[1px]  rounded-md py-2 px-3 mx-1" + ((nr == this.state.data.pageNumber) ? " border-blue-700 bg-blue-700 text-white " : " border-[#DDDDDD]")}
                              onClick={() => this.onPage(nr)}>{nr}
                            </span>}
                          {nr === -1 && <span key={"page" + index} className="px-2 " >...</span>}
                        </span>
                      )
                    }

                    {!this.state.data.isLastPage && this.state.data.pageNumber < this.state.data.totalPages &&

                      <span key={"pageNext"} className="px-2 cursor-pointer border-[1px] rounded-md py-2 px-3 mx-1 border-[#DDDDDD]" onClick={() => this.onPage(this.state.data.pageNumber + 1)} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline" >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>

                      </span>
                    }

                    {!this.state.data.isLastPage && this.state.data.pageNumber < this.state.data.totalPages &&

                      <span key={"pageLast"} className="px-2 cursor-pointer border-[1px] rounded-md py-2 px-3 mx-1 border-[#DDDDDD]" onClick={() => this.onPage(this.state.data.totalPages)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                        </svg>

                      </span>
                    }

                  </span>


                </td>
              </tr>

            </tbody>
          }

          {!(this.state.data != null && this.state.data.students != null && this.state.data.students.length > 0) &&
            <tbody>
              <tr>
                <td colSpan={10} className='text-center p-10'>No students found</td>
              </tr>
            </tbody>
          }


        </table>

        {this.state.deleteid >= 0 &&
          <StudentListingDelete id={this.state.deleteid} cancel={this.onDeleteCancel} callback={this.onDeleteDo} />
        }
        {this.state.mailids != null && this.state.mailids.length > 0 &&
          <StudentListingMail ids={this.state.mailids} cancel={this.onMailCancel} callback={this.onMailComplete} />
        }

        {this.state.deleteids != null && this.state.deleteids.length > 0 &&
          <AdminStudentListingDelete ids={this.state.deleteids} cancel={this.onAdminDeleteCancel} callback={this.onAdminDeleteComplete} />
        }


        {this.state.viewid >= 0 &&
          <StudentListingDetails id={this.state.viewid} cancel={this.onViewCancel} callback={this.onViewCancel} isEdit={false} options={this.state.options} getOptions={this.getOptions} />
        }

        {this.state.editid >= 0 &&
          <StudentListingDetails id={this.state.editid} cancel={this.onEditCancel} callback={this.onEditCancel} isEdit={true} options={this.state.options} getOptions={this.getOptions} />
        }

        {dinfo(this.state)}
      </React.Fragment>
    );
  }
};


export default StudentListing;
