import axios from 'axios';
import saveAs from 'file-saver';
import * as React from 'react';
import { BASEAPI, BaseError, FileUploadRecord, FileUploadResult, dinfo } from '../objects';

interface StudentListingDeleteProps {
    id: number;
    cancel: (() => void);
    callback: (() => void);
}

interface StudentListingDeleteState {
    errorMessage: string;
}



class StudentListingDelete extends React.Component<StudentListingDeleteProps, StudentListingDeleteState> {

    public state: StudentListingDeleteState = {
        errorMessage: "",
    };


    public onDelete = async (id: number) => {

        this.setState({
            errorMessage: ""
        });

        var ids:number[] = [this.props.id];
        axios.post<BaseError>(BASEAPI + `DeleteStudents`,ids)
            .then(data => {
                //if no errors
                if (data.data.errorCode === 0) {
                    this.props.callback();
                }
                else {
                    this.setState({
                        errorMessage: data.data.errorMessage
                    });
                }

            });




    }

    public render() {

        return (

            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={this.props.cancel}>
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto ">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 sm:p-0 ">
                        <div className="relative bg-white rounded-md  text-left shadow-xl transform transition-all sm:my-8 sm:max-w-[60rem] sm:w-full" onClick={e => e.stopPropagation()}>

                            <div className="text-[#000000] p-6 text-center">

                                <h3 className=" text-2xl leading-6 font-medium " id="modal-title">
                                    Are you sure?
                                    <button type="button" className="text-2xl float-right " onClick={this.props.cancel}>x</button>
                                </h3>

                                <div className='font-light text-2xl mt-2'>Are you sure you want to delete this record?</div>
                                <div className=' my-5'>
                                    <img src={require('../assets/trashdelete.png')} className='m-auto w-1/2'></img>
                                </div>
                                {this.state.errorMessage != null && this.state.errorMessage.length > 0 &&
                                    <div className='text-[#FF0000] my-4'>
                                        {this.state.errorMessage}
                                    </div>
                                }

                                <div>
                                    <button type="button" className="text-2xl border-[1px] rounded-md border-[#000000] px-10 py-4" onClick={this.props.cancel}>Cancel</button>
                                    <button type="button" className="text-2xl border-[1px] rounded-md border-[#4f46D9] px-10 py-4 ml-10 bg-[#4f46D9] text-white" onClick={() => this.onDelete(this.props.id)}>Confirm</button>
                                </div>
                            </div>

                            {dinfo(this.props)}
                        </div>
                    </div>
                </div>

            </div>


        );
    }



}
export default StudentListingDelete;