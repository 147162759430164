import * as React from 'react';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment, { Moment } from "moment";
import "moment/locale/en-gb";
import { dinfo } from '../objects';
import Calendar from './Calendar';




interface IDateTimeProps {
  id: string;
  value: string;
  label: string;
  required: boolean;
  readonly: boolean;
  setValue: (id: string, value: string) => void;
}

interface IDateTimeState {
  value: string;
  //showCalendar: boolean;
}

const convertDate = (inputValue: string): string => {

  if (inputValue !== "") {
    var d = moment(inputValue, 'YYYY-MM-DD', true); console.log('DateTimePicker', d, d.isValid());
    if (d.isValid()) {
      return d.format('DD/MM/YYYY');
    }

  }

  return "";

}

class DateTimePicker extends React.Component<IDateTimeProps> {


  public state: IDateTimeState = {
    value: convertDate(this.props.value),
    //showCalendar: false,
  }



  public getAge = (birthday: string) => {

    console.log("birthday", birthday);

    var dob = new Date(birthday);
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var age = year - 1970;

    if (!isNaN(age)) return age + " years";
    return "";
  };


  public render() {
    return (
      <>
        {(this.props.id == 'birthDate') && this.renderDOB()}
        {!(this.props.id == 'birthDate') && this.renderDate()}
      </>

    );

  }



  public onChange = async (e: any) => {
    console.log("DateTimePicker onChange", e, e.target.value);

    var inputValue = e.target.value;

    var isvalid: boolean = false;
    switch (inputValue.length) {

      case 1: if (/^(0|1|2|3)$/.test(inputValue)) {
        isvalid = true;
      } break;
      case 2: if (/^(0[1-9]|[12][0-9]|3[01])$/.test(inputValue)) {
        isvalid = true;
      } break;
      case 3: if (/^(0[1-9]|[12][0-9]|3[01])\/$/.test(inputValue)) {
        isvalid = true;
      } break;
      case 4: if (/^(0[1-9]|[12][0-9]|3[01])\/(0|1)$/.test(inputValue)) {
        isvalid = true;
      } break;
      case 5: if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])$/.test(inputValue)) {
        isvalid = true;
      } break;
      case 6: if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/$/.test(inputValue)) {
        isvalid = true;
      } break;
      case 7: if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(1|2)/.test(inputValue)) {
        isvalid = true;
      } break;
      case 8: if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)/.test(inputValue)) {
        isvalid = true;
      } break;
      case 9: if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19[0-9]|20[012])/.test(inputValue)) {
        isvalid = true;
      } break;
      case 10: if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19[0-9][0-9]|20[012][0-9])/.test(inputValue)) {
        isvalid = true;
      } break;

      default: inputValue = ""; isvalid = true;


    }

    if (isvalid) {

      this.setState({
        value: inputValue,
        showCalendar: false,
      });

      if (inputValue !== "") {
        var d = moment(inputValue, 'DD/MM/YYYY', true); console.log('DateTimePicker', d, d.isValid());
        if (d.isValid()) {
          this.props.setValue(this.props.id, d.format('YYYY-MM-DD'));
        }

      }


    }


  }
  /*
    public onClick = async () => {
      this.setState({
        showCalendar: !this.state.showCalendar,
      });
    }
  */

  public onSelectDate = async (inputValue: string) => {
    if (inputValue !== "") {
      var d = moment(inputValue, 'YYYY-MM-DD', true); console.log('DateTimePicker', d, d.isValid());
      if (d.isValid()) {
        this.setState({
          value: d.format('DD/MM/YYYY'),
          showCalendar: false,
        });
        this.props.setValue(this.props.id, d.format('YYYY-MM-DD'));
      }
    }

    var field: HTMLFormElement = document.getElementById(this.props.id + "-error") as HTMLFormElement;
    if (field != null) {
      field.textContent = '';
    }

  }

  private ShowValidationElement(elem: HTMLInputElement) {
    if (!elem.checkValidity()) {
      console.log("ShowValidationElement", elem.id, elem, elem.checkValidity());
    }

    var m = "";
    if (!elem.checkValidity()) {
      m = elem.validationMessage;
    }
    else {
      m = "";
    }

    var field: HTMLFormElement = document.getElementById(elem.id + "-error") as HTMLFormElement;
    if (field != null) {
      field.textContent = m;
    }

    return m;
  }


  public renderDate() {
    return (
      <>

        <label className='my-2 block'>{this.props.label} {this.props.required && <span className="text-[#FF0000]">*</span>} {dinfo(this.props.value)}</label>
        <div className='group'>
          <input type="text" id={this.props.id}  minLength={10} maxLength={10}
            value={this.state.value}
            onChange={this.onChange}
            //onClick={() => this.onClick()}
            readOnly={this.props.readonly}
            className=' w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2 custom-calendar' placeholder='DD/MM/YYYY' required={this.props.required}

          />

          <span id={this.props.id + "-error"} className='error text-[#FF0000]'></span>
          {/*this.state.showCalendar &&*/}
          <div className='hidden group-hover:block'>
            <Calendar callback={this.onSelectDate} date={this.state.value} />
          </div>
        </div>
      </>
    );
  }

  public renderDOB() {
    return (
      <>

        <label className='my-2 block'>{this.props.label} {this.props.required && <span className="text-[#FF0000]">*</span>} {dinfo(this.props.value)}</label>
        <div className='flex flex-row border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md'>
          <div className='flex w-full'>
            <div className='group flex-1 '>
              <input type="text" id={this.props.id} minLength={10} maxLength={10}
                value={this.state.value} onChange={this.onChange}
                //onClick={() => this.onClick()}
                readOnly={this.props.readonly}
                className='inline w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2 custom-calendar' placeholder='DD/MM/YYYY' required={this.props.required}


              />

              {/*
          <input type="date" id={this.props.id}
            value={this.state.value} onChange={() => { }}
            className=' hidden w-full rounded-md p-2' placeholder='DD/MM/YYYY' required={this.props.required} />


          <Datetime input={true} dateFormat="DD/MM/YYYY" timeFormat={false} closeOnSelect={true} closeOnClickOutside={true} open={this.props.readonly ? false : undefined}
            inputProps={{ placeholder: 'DD/MM/YYYY', required: true, className: 'w-full p-2 bg-[#F8F9FA]' + (this.props.readonly ? "" : " custom-calendar") }}
            className='inline flex-1 mr-2'
            onChange={(v) => this.handleDateChange(this.props.id, v)} isValidDate={isValidDate} initialViewMode={'years'}
            value={this.props.value != null && this.props.value.length > 0 ? moment(this.props.value) : ""}
            locale="en-gb"
          />
*/}

              <div className='hidden group-hover:block'>
                <Calendar callback={this.onSelectDate} date={this.props.value} />
              </div>
            </div>
            {this.state.value != null && this.state.value.length > 0 &&
              <div className='inline-block text-right m-1 ml-2'>
                <div className=' bg-[#4f46D9] text-white inline-block p-1 rounded-md text-right'>{this.getAge(this.props.value)}</div>
              </div>
            }

          </div>

          {/*this.state.showCalendar &&*/}

        </div>
        <span id={this.props.id + "-error"} className='error text-[#FF0000]'></span>
      </>
    );
  }

}


export default DateTimePicker;