import axios from 'axios';
import saveAs from 'file-saver';
import * as React from 'react';

interface StudentListingMenuProps {
    id: number;
    status: string;
    mail: ((id: number) => void);
    delete: ((id: number) => void);
    view: ((id: number) => void);
    edit: ((id: number) => void);
}

interface StudentListingMenuState {
    showhide: boolean,
}



class StudentListingMenu extends React.Component<StudentListingMenuProps, StudentListingMenuState> {

    public state: StudentListingMenuState = {
        showhide: false,
    };

    public render() {

        return (

            <button className="inline-block px-1 group" onClick={this.handleShowHide} data-title={'Info'}>
                <svg height="10" width="40">
                    <circle cx="6" cy="5" r="3" stroke="gray" strokeWidth="1" fill="white" />
                    <circle cx="20" cy="5" r="3" stroke="gray" strokeWidth="1" fill="white" />
                    <circle cx="34" cy="5" r="3" stroke="gray" strokeWidth="1" fill="white" />
                </svg>


                <div className="hidden group-hover:block absolute z-10 border-[#DDDDDD] border-[1px] w-[160px] ml-[-130px]">




                    <div className="relative bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-0 sm:max-w-lg sm:w-full">
                        <div className="bg-white">

                            <div className="text-left ">

                                {this.props.status != 'Registered' &&
                                    <div className='cursor-pointer p-2 hover:bg-[#DDDDDD]' onClick={() => this.props.mail(this.props.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="inline mr-2" viewBox="0 0 16 16">
                                            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                                            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z" />
                                        </svg>
                                        Mail Reminder
                                    </div>
                                }

                                {(this.props.status == 'Loaded' || this.props.status == 'Pending') &&
                                    <div className='cursor-pointer p-2 hover:bg-[#DDDDDD]' onClick={() => this.props.delete(this.props.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="inline mr-2" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                        </svg>
                                        Delete Entry
                                    </div>
                                }
                                <div className='cursor-pointer p-2 hover:bg-[#DDDDDD]' onClick={() => this.props.view(this.props.id)}>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline mr-2">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>


                                    View
                                </div>

                                {(this.props.status == 'Loaded' || this.props.status == 'Pending') &&
                                    <div className='cursor-pointer p-2 hover:bg-[#DDDDDD]' onClick={() => this.props.edit(this.props.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="inline mr-2" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg>
                                        Edit
                                    </div>
                                }


                            </div>
                        </div>

                    </div>


                </div>

            </button>
        );
    }







    private handleShowHide = () => {
        this.setState({
            showhide: (!this.state.showhide)
        });
    }



}
export default StudentListingMenu;