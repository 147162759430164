
import React from "react";
import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <div className="">
        <Outlet />
      </div>

                {/*<IdleManager />*/}


    </>
  )
};

export default Layout;
