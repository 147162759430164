import React from "react";
import AccessPopup from "../components/AccessPopup";
import { UserId } from "../objects";

const NoAccessPage = () => {
    return <>
        <AccessPopup />
    </>
};

export default NoAccessPage;