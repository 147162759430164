import axios from 'axios';
import saveAs from 'file-saver';
import * as React from 'react';
import { BASEAPI, BaseError, FileUploadRecord, FileUploadResult, LogoutURL, MicrosoftURL, dinfo } from '../objects';
import { format } from 'date-fns'
import moment from 'moment';

interface SessionPopupProps {

}

interface SessionPopupState {
    remainingTime: number;
}


class SessionPopup extends React.Component<SessionPopupProps, SessionPopupState> {
    private intervalId: NodeJS.Timeout | null = null;

    public state: SessionPopupState = {
        remainingTime: 180,
    };

    public componentDidMount() {

        this.intervalId = setInterval(() => {
            if (this.state.remainingTime < 0) {
                //window.location.href = LogoutURL;
            } else {
                this.setState((prevState) => ({ remainingTime: prevState.remainingTime - 1 }));
            }
        }, 1000);
        /*
                setInterval(function () {

                    var now = moment(new Date());
                    var last =  moment(new Date(this.state.lastActive));
                    const diff = now.diff(last);
                    const diffDuration = moment.duration(diff);

                    this.setState({
                        lastActive : diffDuration.minutes() + ':' +diffDuration.seconds()
                    });


                }, 1000);
        */
    }





    public render() {

        const { remainingTime } = this.state;
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        const display = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return (

            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto ">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 sm:p-0 ">
                        <div className="relative bg-white rounded-md  text-left shadow-xl transform transition-all sm:my-8 sm:max-w-[40rem] sm:w-full" onClick={e => e.stopPropagation()}>

                            <div className="text-[#000000] p-6 text-center">

                                {/*
                                <h3 className=" text-2xl leading-6 font-medium " id="modal-title">
                                    Session

                                </h3>
*/}
                                {this.state.remainingTime > 0 &&
                                    <>
                                        <h3 className=" text-2xl leading-6 font-medium " id="modal-title">
                                            Are you still there?
                                        </h3>
                                        <div className='mt-10 '>
                                            If not, we'll close this session in: {display}
                                        </div>

                                        <div className='mt-10 '>
                                            <a href={MicrosoftURL} className='text-2xl border-[1px] rounded-md border-[#000000] px-10 py-4' >I'm here</a>
                                            <a href={LogoutURL} className='text-2xl border-[1px] rounded-md border-[#000000] px-10 py-4 m-4'>Sign out</a>
                                        </div>
                                    </>
                                }

                                {this.state.remainingTime <= 0 &&
                                    <>
                                        <h3 className=" text-2xl leading-6 font-medium " id="modal-title">
                                            Session ended
                                        </h3>
                                        <div className='mt-10 '>
                                            There was no activity for a while so we closed the session
                                        </div>

                                        <div className='mt-10 '>
                                            <a href={LogoutURL} className='text-2xl border-[1px] rounded-md border-[#000000] px-10 py-4 m-4'>Start new session</a>
                                        </div>
                                    </>
                                }


                            </div>

                            {dinfo(this.props)}
                        </div>
                    </div>
                </div>

            </div>


        );
    }



}
export default SessionPopup;