import axios from 'axios';
import saveAs from 'file-saver';
import * as React from 'react';
import { BASEAPI, BaseError, FileUploadRecord, FileUploadResult, LogoutURL, MicrosoftURL, dinfo } from '../objects';

interface AccessPopupProps {

}

interface AccessPopupState {
}


class AccessPopup extends React.Component<AccessPopupProps, AccessPopupState> {


    public render() {

        return (

            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto ">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 sm:p-0 ">
                        <div className="relative bg-white rounded-md  text-left shadow-xl transform transition-all sm:my-8 sm:max-w-[60rem] sm:w-full" onClick={e => e.stopPropagation()}>

                            <div className="text-[#000000] p-6 text-center">

                                <h3 className=" text-2xl leading-6 font-medium " id="modal-title">
                                    Access Issue

                                </h3>

                                <div className='mt-10 '>
                                You don't have permission to access, please contact firms@icas.com if you need access.
                                </div>

                                <div className='mt-10 '>
                                    <a href={LogoutURL} className='text-2xl border-[1px] rounded-md border-[#000000] px-10 py-4' >Continue</a>

                                </div>

                            </div>

                            {dinfo(this.props)}
                        </div>
                    </div>
                </div>

            </div>


        );
    }



}
export default AccessPopup;