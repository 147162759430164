import React from "react";
import UserListing from "../components/UserListing";
import StudentList from "../components/StudentListing";
import { UserId, dinfo } from "../objects";
import Header from "../components/Header";
import IdleManager from "../IdleManager";


interface AdminPageProps {


}

interface AdminPageState {
    org: string;
    orgName: string;
    showStudents: boolean;

}


class AdminPage extends React.Component<AdminPageProps, AdminPageState> {

    public state: AdminPageState = {
        org: "",
        orgName: "",
        showStudents: false,
    }


    public callback = (org: string, orgName: string) => {
        console.log("callback", org, orgName);

        this.setState({ org: org, orgName: orgName });
    }

    public onNavigate = (showStudents: boolean) => {
        console.log("onNavigate", showStudents);

        this.setState({ showStudents: showStudents });
    }


    public render() {

        return (

            <div className="w-[100%]">
                <Header org={this.state.org} callback={this.callback} userId={UserId} isAdmin={true} />

                {UserId != null && UserId.length > 0 &&
                    <>
                        {!this.state.showStudents &&
                            <div className="p-5">
                                <UserListing org={this.state.org} orgName={this.state.orgName} onNavigate={this.onNavigate} />
                            </div>
                        }

                        {this.state.showStudents &&
                            <div className="p-5">
                                <StudentList org={this.state.org} orgName={this.state.orgName} userId={UserId} isAdmin={true} onNavigate={this.onNavigate} />
                            </div>

                        }
                    </>

                }

                <IdleManager />
                {dinfo(this.state)}
            </div>


        );
    }

}



export default AdminPage;