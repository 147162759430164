import React, { Component } from "react";

import { withRouter, WithRouterProps } from '../components/withRouter';
import { Link } from "react-router-dom";
import HeaderStudent from "../components/HeaderStudent";
import StudentForm from "../components/StudentForm";
import Email from "../components/Email";

interface Params {
    identifier: string;
    email:string;
}

interface IState {
    isValidStudent: boolean;
    processing: boolean;
    name: string;
    errorMessage: string;
}

type Props = WithRouterProps<Params>;

class StudentPage extends Component<Props, IState> {


    public state: IState = {
        isValidStudent: false,
        errorMessage: "",
        processing: false,
        name:""
    };


    public componentDidMount() {
        const { match } = this.props;
        if (!this.state.processing) {
        //validate student by identifier
this.setState({ isValidStudent: true, name: match.params.identifier});
        }
    }



    render() {
        const { match } = this.props;
        console.log("identifier",match.params.identifier); // with autocomplete
        console.log("email",window.location.href.indexOf('email=1')>0); // with autocomplete

        var pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

        if (pattern.test(match.params.identifier) === true) {
            console.log('We have a winner!');
          } else {
            console.log('This is not a valid GUID.');
          }

if (window.location.href.indexOf('email=1')>0) {

    return (
    <Email identifier={match.params.identifier} />
    );
}
else {
        return (
            <>

<HeaderStudent title="Complete your Registration" name={this.state.name}/>
            {(match.params.identifier!=null && match.params.identifier.length>0) &&

<>
               {/*<span>match.params.identifier: {match.params.identifier}</span>*/}

               <StudentForm identifier={match.params.identifier} />
               </>
            }
            </>

        );
    }
}
}

export default withRouter(StudentPage);