import * as React from 'react';
import { Link } from 'react-router-dom'; //https://reactrouter.com/en/main/components/nav-link
import { UserName } from '../objects';
import Account from './Account';

interface IHeaderProps {
  title: string;
  name: string;
}


class HeaderStudent extends React.Component<IHeaderProps> {

  public render() {

    return (
      <React.Fragment>
        {/*
<div>
window.location.origin:{window.location.origin}
</div>
<div>
require('../assets/icas.png'){require('../assets/icas.png')}
</div>
*/}

        <div className="flex bg-[#4f46D9] px-3 py-2 h-[120px]">

          <div className='w-1/4'>
          <Link to="/" className="float-left"><img src={window.location.origin + require('../assets/icas.png').replace('./', '/')} className='inline h-[100px] bg-white' title="ICAS" alt="ICAS"></img></Link>

        </div>
        <div className='w-2/4 text-center'>
          <span className="text-white leading-[100px] text-2xl font-bold">{this.props.title}</span>
        </div>

        {/*
<span className="text-white float-right mt-10 mr-10">
{this.props.name}
  </span>
*/}


      </div>
      </React.Fragment >
    );
  }
};


export default HeaderStudent;