import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";



import { useEffect } from "react";

import Page404 from './pages/Page404';
import TPPage from './pages/TPPage';
import StudentPage from './pages/StudentPage';
import AdminPage from './pages/AdminPage';
import Layout from "./Layout";
import React from 'react';
import ClearCache from './pages/ClearCache';
import { UserId, isATO, isAdmin } from './objects';
import UserImpersonatePage from './pages/UserImpersonatePage';
import LoginPage from './pages/LoginPage';
import NoAccessPage from './pages/NoAccessPage';
//import AdminStudentPage from './pages/AdminStudentPage';

function App() {


    useEffect(() => {
        //alert(isAdmin);
        if (isAdmin) {
            const path = window.location.pathname;
            if (path === "/") {
                window.location.href = "/admin";
            }
        }

        if (!isAdmin && !isATO && UserId!=null && UserId.length>0) {
            const path = window.location.pathname;
            if (path === "/") {
                window.location.href = "/noaccess";
            }
        }

    }, []);






    if (isAdmin) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/:identifier" element={<Layout />}>
                        <Route index element={<UserImpersonatePage />} />
                    </Route>
{/*
                    <Route path="/" element={<Layout />}>
                        <Route index element={<TPPage />} />
                    </Route>
 */}
                    <Route path="form/:identifier" element={<Layout />}>
                        <Route index element={<StudentPage />} />
                    </Route>


                    <Route path="/admin" element={<Layout />}>
                        <Route index element={<AdminPage />} />
                    </Route>
{/*
                    <Route path="/admin/students" element={<Layout />}>
                        <Route index element={<AdminStudentPage />} />
                    </Route>
 */}
                    <Route path="/admin/ClearCache" element={<ClearCache />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        );
    }
    else if (isATO) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<TPPage />} />
                    </Route>

                    <Route path="form/:identifier" element={<Layout />}>
                        <Route index element={<StudentPage />} />
                    </Route>

                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        );
    }
    else {
        //simple user
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<LoginPage />} />
                    </Route>

                    <Route path="form/:identifier" element={<Layout />}>
                        <Route index element={<StudentPage />} />
                    </Route>

                    <Route path="noaccess" element={<Layout />}>
                        <Route index element={<NoAccessPage />} />
                    </Route>

                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        );
    }
}



export default App;