import React from "react";
import Header from "../components/Header";
import Autocomplete from "../components/Autocomplete";
import axios from "axios";
import { BASEAPI } from "../objects";


const ClearCache = () => {





    const ClearCache = async () => {
        axios.delete<string>(BASEAPI + 'ClearCache')
        .then(data => {
            alert(data.data);
        }).finally(() => {

        }
        );
    }

    return (

<div className="p-5">
<button type="button" className=" border-[1px] rounded-md border-[#4f46D9] px-10 py-2 ml-5 bg-[#4f46D9] text-white" onClick={() => ClearCache()}>ClearCache</button>



        </div>


    );
};

export default ClearCache;