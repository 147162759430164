import axios from 'axios';
import saveAs from 'file-saver';
import * as React from 'react';
import { FileUploadRecord, FileUploadResult, dinfo } from '../objects';

interface StudentListingResultsProps {
    results: FileUploadResult;
    callback: (() => void);
}

interface StudentListingResultsState {
    showIndex: number;
}



class StudentListingResults extends React.Component<StudentListingResultsProps, StudentListingResultsState> {

    public state: StudentListingResultsState = {
        showIndex: 0,
    };


    public render() {


        var failedCount = 0;
        if (this.props.results.failed!=null && this.props.results.failed.length>0) {
            const uniqueNums = new Set(this.props.results.failed.map(obj => obj.row));
            failedCount = uniqueNums.size;
        }

        return (

            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={this.handleShowHide}>
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto ">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 sm:p-0 ">
                        <div className="relative bg-white rounded-md  text-left shadow-xl transform transition-all sm:my-8 sm:max-w-[60rem] sm:w-full" onClick={e => e.stopPropagation()}>

                            <div className="text-[#000000] p-6">

                                <h3 className=" text-lg leading-6 font-medium " id="modal-title">
                                    Upload Results
                                    <button type="button" className="text-2xl float-right " onClick={this.handleShowHide}>x</button>
                                </h3>

                                {dinfo(this.props)}

                                {this.props.results.updated.length > 0 &&
                                    <div className={' mt-6  text-gray-500 bg-gray-100 border-[1px]  rounded-md ' + (this.state.showIndex == 2 ? 'border-[#000000] pb-5' : 'border-gray-200')}>
                                        <h2 className="flex  p-5 items-center justify-between w-full font-medium text-left">
                                            <span className={this.state.showIndex === 2 ? ' text-[#000000] mb-3' : ''}>Updated Records ({this.props.results.updated.length})</span>
                                        </h2>
                                        {/*

                                        <h2 onClick={() => this.onShowIndex(2)} className="flex  p-5 items-center justify-between w-full font-medium text-left">
                                            <span className={this.state.showIndex === 2 ? ' text-[#000000] mb-3' : ''}>Updated Records ({this.props.results.updated.length})</span>
                                            {this.state.showIndex !== 2 &&
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#4f46D9" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                                </svg>}
                                            {this.state.showIndex === 2 &&
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#4f46D9" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                </svg>
                                            }
                                        </h2>
                                        {this.state.showIndex == 2 &&
                                            <div className="" >
                                                {this.props.results.updated != null && this.props.results.updated.length > 0 &&
                                                    <>
                                                        <div className="flex px-5 border">
                                                            <div className='w-1/3 p-3 bg-white border border-[1px] border-gray-200 font-medium'>Row</div>
                                                            <div className='w-2/3 p-3 bg-white border border-[1px] border-l-[2px] border-gray-200 font-medium'>Reason</div>
                                                        </div>
                                                        {this.props.results.updated.map((record: FileUploadRecord, index: number) =>

                                                            <div className="flex px-5 border" key={'email11'+index}>
                                                                <div  key={'email11'+index} className='w-1/3 p-3 bg-white border border-[1px] border-gray-200 '>{record.row} </div>
                                                                <div  key={'email12'+index} className='w-2/3 p-3 bg-white border border-[1px] border-l-[2px] border-gray-200 '>{record.reason}</div>
                                                            </div>

                                                        )}
                                                    </>
                                                }
                                            </div>

                                        }
                                        */}
                                    </div>
                                }
                                {this.props.results.created.length > 0 &&
                                    <div className={' mt-6  text-gray-500 bg-gray-100 border-[1px]  rounded-md ' + (this.state.showIndex == 1 ? 'border-[#000000] pb-5' : 'border-gray-200')}>
                                        <h2 className="flex p-5 items-center justify-between w-full font-medium text-left">
                                            <span className={this.state.showIndex === 1 ? ' text-[#00000] mb-3' : ''}>Created Records ({this.props.results.created.length})</span>
                                        </h2>

                                        {/*
                                        <h2 onClick={() => this.onShowIndex(1)} className="flex p-5 items-center justify-between w-full font-medium text-left">
                                            <span className={this.state.showIndex === 1 ? ' text-[#00000] mb-3' : ''}>Created Records ({this.props.results.created.length})</span>
                                            {this.state.showIndex !== 1 &&
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#4f46D9" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                                </svg>}
                                            {this.state.showIndex === 1 &&
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#4f46D9" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                </svg>
                                            }
                                        </h2>
                                        {this.state.showIndex == 1 &&
                                            <div className="" >
                                                {this.props.results.created != null && this.props.results.created.length > 0 &&
                                                    <>
                                                        <div className="flex px-5 border">
                                                            <div className='w-1/3 p-3 bg-white border border-[1px] border-gray-200 font-medium'>Row </div>
                                                            <div className='w-2/3 p-3 bg-white border border-[1px] border-l-[2px] border-gray-200 font-medium'>Reason</div>
                                                        </div>
                                                        {this.props.results.created.map((record: FileUploadRecord, index: number) =>
                                                            <>


                                                                <div className="flex px-5 border" key={'email2'+index}>
                                                                    <div  key={'email21'+index} className='w-1/3 p-3 bg-white border border-[1px] border-gray-200 '>{record.row} </div>
                                                                    <div  key={'email22'+index} className='w-2/3 p-3 bg-white border border-[1px] border-l-[2px] border-gray-200 '>{record.reason}</div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                }
                                            </div>

                                        }
                                        */}
                                    </div>
                                }
                                {this.props.results.failed.length > 0 &&
                                    <div className={'mt-6  text-gray-500 bg-gray-100 border-[1px]  rounded-md ' + (this.state.showIndex == 0 ? 'border-[#FF0000] pb-5' : 'border-gray-200')}>
                                        <h2 onClick={() => this.onShowIndex(0)} className="flex p-5 items-center justify-between w-full font-medium text-left">
                                            <span className={this.state.showIndex === 0 ? ' text-[#FF0000] mb-3' : ''}>Failed Records ({failedCount})</span>
                                            {this.state.showIndex !== 0 &&
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#4f46D9" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                                </svg>}
                                            {this.state.showIndex === 0 &&
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#4f46D9" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                </svg>
                                            }
                                        </h2>
                                        {this.state.showIndex == 0 &&
                                            <div className="" >
                                                {this.props.results.failed != null && this.props.results.failed.length > 0 &&
                                                    <>
                                                        <div className="flex px-5 border">
                                                            <div className='w-1/6 p-3 bg-white border border-[1px] border-gray-200 font-medium '>Row </div>
                                                            <div className='w-1/3 p-3 bg-white border border-[1px] border-gray-200 font-medium '>Field</div>
                                                            <div className='w-1/2 p-3 bg-white border border-[1px] border-l-[2px] border-gray-200 font-medium'>Reason</div>
                                                        </div>
                                                        {this.props.results.failed.map((record: FileUploadRecord, index: number) =>
                                                            <>


                                                                <div className="flex px-5 border" key={'email3'+index}>
                                                                <div  key={'email31'+index} className='w-1/6 p-3 bg-white border border-[1px] border-gray-200 '>{record.row} </div>
                                                                    <div  key={'email31'+index} className='w-1/3 p-3 bg-white border border-[1px] border-gray-200 '>{record.field} </div>
                                                                    <div  key={'email32'+index} className='w-1/2 p-3 bg-white border border-[1px] border-l-[2px] border-gray-200 '>{record.reason}</div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                }
                                            </div>

                                        }
                                    </div>
    }

{this.props.results.errorMessage!=null && this.props.results.errorMessage.length>0 &&

<div className="text-[#FF0000] font-bold whitespace-normal">
    {this.props.results.errorMessage}
</div>

}

                            </div>



                        </div>
                    </div>
                </div>

            </div>


        );
    }







    private handleShowHide = () => {
        this.props.callback();
    }

    private onShowIndex = (index: number) => {

        this.setState({
            showIndex: (this.state.showIndex != index) ? index : -1
        });
    }


}
export default StudentListingResults;