import React from "react";
import StudentList from "../components/StudentListing";
import { UserId, dinfo } from "../objects";
import Header from "../components/Header";
import IdleManager from "../IdleManager";


interface TPPageProps {


}

interface TPPageState {
    org: string;
    orgName: string;

}


class TPPage extends React.Component<TPPageProps, TPPageState> {

    public state: TPPageState = {
        org: "",
        orgName:"",
    }


    public callback = (org: string, orgName:string) => {
        console.log("callback", org, orgName);

        this.setState({ org: org , orgName: orgName});
      }



    public render() {

        return (

            <div className="w-[100%]">
                <Header org={this.state.org} callback={this.callback} userId={UserId} isAdmin={false} />

                {UserId != null && UserId.length > 0 &&
                    <div className="p-5">
                        <StudentList org={this.state.org} orgName={this.state.orgName} userId={UserId} isAdmin={false}  onNavigate={(showStudents: boolean) => {}} />
                    </div>
                }

<IdleManager />
                {dinfo(this.state)}
            </div>


        );
    }

}



export default TPPage;