import axios from 'axios';
import * as React from 'react';
import { BASEAPI, BaseError, StudentUploadOptions, StudentUpload, dinfo, SelectOption, todo, AuditTrail } from '../objects';
import DateTimePicker from './DateTimePicker';

interface StudentListingDetailsProps {
    id: number;
    isEdit: boolean;
    cancel: (() => void);
    callback: (() => void);
    options: StudentUploadOptions | undefined;
    getOptions: (() => void);
}

interface StudentListingDetailsState {
    id: number;
    needData: boolean;
    errorMessage: string;
    student: StudentUpload;

    showAudit: boolean;
    needDataAudit: boolean;
    audits: AuditTrail[] | undefined;
}



class StudentListingDetails extends React.Component<StudentListingDetailsProps, StudentListingDetailsState> {

    public state: StudentListingDetailsState = {
        id: this.props.id,
        errorMessage: "",
        needData: true,

        student: {
            id: 0,
            identifier: "",
            status: "",
            organizationNumber: "",
            organisationName: "",

            firstName: "",
            lastName: "",
            email: "",
            studentType: "",
            startDate: "",
            provider: "",
            qualification: "",
            prefferedClassLocation: "",
            employer: "",
            pathWay: "",
            pathWayComments: "",
            //workDepartment: "",
            workSpecialism: "",
            exceptionApproved: "",
            exceptionOutcome: "",
            startingTerm: "",
            selected: false
        },
        showAudit: false,
        needDataAudit: false,
        audits: [],

    };



    private GetData() {
        if (this.state.needData && this.state.id > 0) {
            axios.get<StudentUpload>(BASEAPI + `GetStudent?id=${this.props.id}`)
                .then(data => {
                    this.setState({
                        needData: false,
                        student: data.data

                    });
                });
        }

        if (this.props.options !== undefined && this.props.options !== null) {
            //all OK
        }
        else {
            this.props.getOptions();

        }

    }

    private GetDataAudit() {
        console.log("GetDataAudit");
        if (this.state.id > 0) {
            axios.get<AuditTrail[]>(BASEAPI + `GetAudits?id=${this.props.id}`)
                .then(data => {
                    this.setState({
                        needDataAudit: false,
                        audits: data.data
                    });
                });
        }

        if (this.props.options !== undefined && this.props.options !== null) {
            //all OK
        }
        else {
            this.props.getOptions();

        }

    }


    public componentDidMount() {
        this.GetData();
    }
    public componentDidUpdate() {
        this.GetData();
    }

    public onSubmit = async (id: number) => {

        console.log("onSubmit", id);

        var f: HTMLFormElement = document.getElementById("testForm") as HTMLFormElement;

        /*
                var field1: HTMLFormElement = document.getElementById("studenttype1") as HTMLFormElement;
                field1.setCustomValidity('Please select one option to proceed.');

                var field2: HTMLFormElement = document.getElementById("studenttype1") as HTMLFormElement;
                field2.setCustomValidity('Please select one option to proceed.');
        */
        if (f != null && f.checkValidity()) {

            this.setState({
                errorMessage: ""
            });

            axios.post<BaseError>(BASEAPI + `SaveStudent`, this.state.student)
                .then(data => {
                    //if no errors
                    if (data.data.errorCode === 0) {
                        this.props.callback();
                    }
                    else {
                        this.setState({
                            errorMessage: data.data.errorMessage
                        });
                    }

                });

        }
        else {

            this.ShowValidations(f);

        }


    }

    private ShowValidationElement(elem: HTMLInputElement) {
        console.log("ShowValidationElement");
        var m = "";
        if (!elem.checkValidity()) {

            if (elem.id == "startingTerm" && elem.validationMessage.indexOf('Please lengthen') >= 0) {
                m = 'Invalid field, must be DD/MM/YYYY';
            }
            else  if (elem.id == "startDate" && elem.validationMessage.indexOf('Please lengthen') >= 0) {
                m = 'Invalid field, must be DD/MM/YYYY';
            }
            else {
            m = elem.validationMessage;
            }
        }
        else {
            m = "";
        }

        var field: HTMLFormElement = document.getElementById(elem.id + "-error") as HTMLFormElement;
        if (field != null) {
            field.textContent = m;
        }

        return m;
    }

    private ShowValidations(f: HTMLFormElement) {
        var M = "";
        Array.from(f.elements).forEach((input) => {
            console.log(input);
            var elem: HTMLInputElement = input as HTMLInputElement;
            M += " " + this.ShowValidationElement(elem);

        });
        console.log(M);
    }

    public onChange = async (id: string, event: any) => {
        if (!this.props.isEdit) return;

        const value: string = event.target.value;

        this.setValue(id, value);

    }

    public setValue = async (id: string, value: string) => {

        console.log("onChange", id, value);

        var student = { ...this.state.student };
        if (student != null) {
            switch (id) {
                case "firstName": student.firstName = value; break;
                case "lastName": student.lastName = value; break;
                case "email": student.email = value; break;
                case "employer": student.employer = value; break;
                case "studentType": student.studentType = value; break;
                case "startDate": student.startDate = value; break;
                case "provider": student.provider = value; break;
                case "qualification": student.qualification = value; break;
                case "pathWay": student.pathWay = value; break;
                case "pathWayComments": student.pathWayComments = value; break;
                //case "workDepartment": student.workDepartment = value; break;
                case "workSpecialism": student.workSpecialism = value; break;
                case "exceptionApproved": student.exceptionApproved = value; break;
                case "exceptionOutcome": student.exceptionOutcome = value; break;
                case "startingTerm": student.startingTerm = value; break;
                case "prefferedClassLocation": student.prefferedClassLocation = value; break;

            }

        }
        this.setState({
            student: student
        });

        var input: HTMLInputElement = document.getElementById(id) as HTMLInputElement;
        if (input != null && input.checkValidity()) {
            this.ShowValidationElement(input);
        }

    }

    public audit = async (show: boolean) => {
        console.log("audit", show);
        this.setState({
            showAudit: show,
            needDataAudit: (show == true),
        });
        if (show == true) this.GetDataAudit();
    }

    public render() {

        return (

            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" > {/*onClick={this.props.cancel} */}
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto ">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 sm:p-0 ">
                        <div className="relative bg-white rounded-md  text-left shadow-xl transform transition-all sm:my-8 sm:max-w-[60rem] sm:w-full" onClick={e => e.stopPropagation()}>

                            <div className="text-[#000000] p-6">

                                <h3 className=" text-2xl leading-6 font-medium px-2" id="modal-title">
                                    {!this.state.showAudit && !this.props.isEdit && <span>View Student Details</span>}
                                    {!this.state.showAudit && this.props.isEdit && <span>Edit Student Details</span>}
                                    {this.state.showAudit && <span>Audit Trail for <span className='text-[#4f46D9]'>{this.state.student?.firstName}</span></span>}

                                    <button type="button" className="text-2xl float-right ml-3 " onClick={this.props.cancel}>x</button>

                                    {!this.state.showAudit &&
                                        <button type="button" className="text-sm inline w-[10rem] border-[1px] rounded-md p-2 border-[#F88A2A] bg-[#F88A2A] text-white cursor-pointer ml-4 h-[40px] text-center float-right "
                                            onClick={() => this.audit(true)}>View Audit Trail</button>
                                    }

                                    {this.state.showAudit &&
                                        <button type="button" className="text-sm inline w-[12rem] border-[1px] rounded-md p-2 border-[#000000] bg-gray-50 text-[#000000] cursor-pointer ml-4 h-[40px] text-center float-right "
                                            onClick={() => this.audit(false)}>Back to Student Details</button>
                                    }


                                </h3>

                                {!this.state.showAudit && this.renderData()}
                                {this.state.showAudit && this.renderAudit()}

                            </div>

                            {dinfo(this.props.options)}  {dinfo(this.state)}
                        </div>
                    </div>
                </div>

            </div>


        );

    }

    public renderAudit() {
        return (
<div className='mt-5'>

                {this.state.audits?.map((record: AuditTrail, index: number) =>
                    <div className='mt-3'>
                        <div key={'o2' + index} className='w-full'  >
                            <span className='rounded-2xl text-[#FFFFFF] bg-[#4f46D9] px-2 py-1 ml-3'>{record.changeDate}</span>
                            <div className='bg-gray-50 p-3 m-3'>
                                <div className='p-2 text-2xl font-bold'>{record.status}</div>
                                <div className='p-2 '>{record.description} </div>
                                <div className='p-2 '>User: <span className='text-[#4f46D9] font-bold'>{record.userName}</span></div>

                            </div>
                        </div>
                    </div>
                )
                }
</div>
        );

    }
    public renderData() {

        return (
            <>
                <div className='my-5'>

                    {!this.state.needData && this.state.student != null &&
                        <form id="testForm" noValidate className="needValidation" >


                            <div className='flex text-left px-2 pt-2'>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>First Name <span className="text-[#FF0000]">*</span></label>
                                    <input type="text" id="firstName" maxLength={50}
                                        value={this.state.student?.firstName}
                                        onChange={(e) => this.onChange('firstName', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                        placeholder='Enter first name' required={true} readOnly={!this.props.isEdit} />
                                    <span id="firstName-error" className='text-[#FF0000]'></span>
                                </div>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Last Name <span className="text-[#FF0000]">*</span></label>
                                    <input type="text" id="lastName" maxLength={50}
                                        value={this.state.student?.lastName}
                                        onChange={(e) => this.onChange('lastName', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter last name' required={true} readOnly={!this.props.isEdit} />
                                    <span id="lastName-error" className='text-[#FF0000]'></span>
                                </div>
                            </div>

                            <div className='flex text-left px-2 pt-2'>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Email Address <span className="text-[#FF0000]">*</span></label>
                                    <input type="email" id="email"
                                        value={this.state.student?.email} maxLength={100} required={true}
                                        onChange={(e) => this.onChange('email', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter email address' readOnly={!this.props.isEdit} />
                                    <span id="email-error" className='text-[#FF0000]'></span>
                                </div>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Employer/Organisation Number</label>
                                    <input type="text" id="employer" maxLength={6}
                                        value={this.state.student?.employer}
                                        onChange={(e) => this.onChange('employer', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter employer/organisation number' readOnly={!this.props.isEdit} />
                                    <span id="organisation-error" className='text-[#FF0000]'></span>
                                </div>
                            </div>

                            <div className='flex text-left px-2 pt-2'>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Student Type <span className="text-[#FF0000]">*</span></label>
                                    <div className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                        <label key={"studenttype1"} htmlFor="studenttype1">
                                            <input type="radio" id="studenttype1" name="studenttype"
                                                value="Apprentice"
                                                checked={this.state.student?.studentType === "Apprentice"}
                                                onChange={(e) => this.onChange('studentType', e)}
                                                className='ml-2 mr-2' />Apprentice
                                        </label>
                                        <label key={"studenttype2"} htmlFor="studenttype2">
                                            <input type="radio" id="studenttype2" name="studenttype"
                                                value="Student"
                                                checked={this.state.student?.studentType === "Student"}
                                                onChange={(e) => this.onChange('studentType', e)}
                                                className='ml-6 mr-2' />Student
                                        </label>
                                    </div>
                                    <span id="studenttype1-error" className='text-[#00FF00]'></span>
                                    <span id="studenttype2-error" className='text-[#FF0000]'></span>
                                </div>
                                <div className='w-1/2 px-2 py-1'>
                                    {/*
                <label className='my-2 block'>Training Contract Start Date <span className="text-[#FF0000]">*</span></label>
                <input type="date" id="startDate"
                    value={this.state.student?.startDate}
                    onChange={(e) => this.onChange('startDate', e)}
                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='DD/MM/YYYY' required={true} readOnly={!this.props.isEdit} />
                <span id="startDate-error" className='text-[#FF0000]'></span>
*/}
                                    <DateTimePicker id="startDate" value={this.state.student?.startDate} label="Training Contract Start Date" required={true} setValue={this.setValue} readonly={!this.props.isEdit} />

                                </div>
                            </div>

                            <div className='flex text-left px-2 pt-2'>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Training Provider <span className="text-[#FF0000]">*</span></label>
                                    <select id="provider"
                                        value={this.state.student?.provider}
                                        onChange={(e) => this.onChange('provider', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                        <option value="">Select</option>
                                        {this.props.options?.provider.map((record: SelectOption, index: number) =>
                                            <option key={'o' + index} value={record.value}>{record.text}</option>
                                        )}
                                    </select>
                                    <span id="provider-error" className='text-[#FF0000]'></span>
                                </div>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Qualification <span className="text-[#FF0000]">*</span></label>
                                    <select id="qualification"
                                        value={this.state.student?.qualification}
                                        onChange={(e) => this.onChange('qualification', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                        <option value="">Select</option>
                                        {this.props.options?.qualification.map((record: SelectOption, index: number) =>
                                            <option key={'o' + index} value={record.value}>{record.text}</option>
                                        )}

                                    </select>
                                    <span id="qualification-error" className='text-[#FF0000]'></span>
                                </div>
                            </div>

                            <div className='flex text-left px-2 pt-2'>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Pathway</label>
                                    <select id="pathWay"
                                        value={this.state.student?.pathWay}
                                        onChange={(e) => this.onChange('pathWay', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2.5'>
                                        <option value="">Select</option>
                                        {this.props.options?.pathWay.map((record: SelectOption, index: number) =>
                                            <option key={'o' + index} value={record.value}>{record.text}</option>
                                        )}
                                    </select>
                                </div>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Pathway Comments</label>
                                    <input type="text" id="pathWayComments"
                                        value={this.state.student?.pathWayComments}
                                        onChange={(e) => this.onChange('pathWayComments', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Free text' />
                                </div>
                            </div>

                            <div className='flex text-left px-2 pt-2'>
                                {/*
            <div className='w-1/2 px-2 py-1'>
                <label className='my-2 block'>Work Department</label>
                <select id="workDepartment"
                    value={this.state.student?.workDepartment}
                    onChange={(e) => this.onChange('workDepartment', e)}
                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2.5'>
                    <option value="">Select</option>
                    {this.props.options?.workDepartment.map((record: SelectOption, index: number) =>
                        <option key={'o' + index} value={record.value}>{record.text}</option>
                    )}
                </select>
            </div>
             */}
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Working Specialism </label>
                                    <select
                                        id="workSpecialism"
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2.5'
                                        value={this.state.student?.workSpecialism}
                                        onChange={(e) => this.onChange('workSpecialism', e)}
                                    >
                                        <option value="">Select</option>
                                        {this.props.options?.workSpecialism.map((record: SelectOption, index: number) =>
                                            <option key={'o' + index} value={record.value}>{record.text}</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className='flex text-left px-2 pt-2'>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Exemption Approved</label>


                                    <select
                                        id="exceptionApproved"
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2.5'
                                        value={this.state.student?.exceptionApproved}
                                        onChange={(e) => this.onChange('exceptionApproved', e)}
                                    >
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Exemption Outcome</label>
                                    <input type="text" id="exceptionOutcome"
                                        value={this.state.student?.exceptionOutcome}
                                        onChange={(e) => this.onChange('exceptionOutcome', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Free text' readOnly={!this.props.isEdit} />
                                </div>
                            </div>

                            <div className='flex text-left px-2 pt-2'>
                                <div className='w-1/2 px-2 py-1'>
                                    {/*
                <label className='my-2 block'>Intake Date</label>
                <input type="date" id="startingTerm"
                    value={this.state.student?.startingTerm}
                    onChange={(e) => this.onChange('startingTerm', e)}
                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='DD/MM/YYYY' readOnly={!this.props.isEdit} />
*/}
                                    <DateTimePicker id="startingTerm" value={this.state.student?.startingTerm} label="Intake Date" required={true} setValue={this.setValue} readonly={!this.props.isEdit} />
                                </div>
                                <div className='w-1/2 px-2 py-1'>
                                    <label className='my-2 block'>Preferred Class Location <span className="text-[#FF0000]">*</span></label>
                                    <select id="prefferedClassLocation"
                                        value={this.state.student?.prefferedClassLocation}
                                        onChange={(e) => this.onChange('prefferedClassLocation', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2.5' required={true}>
                                        <option value="">Select</option>
                                        {this.props.options?.prefferedClassLocation.map((record: SelectOption, index: number) =>
                                            <option key={'o' + index} value={record.value}>{record.text}</option>
                                        )}

                                    </select>
                                    <span id="prefferedClassLocation-error" className='text-[#FF0000]'></span>
                                </div>

                            </div>
                        </form>
                    }
                    {this.state.student == null &&
                        <div className='text-center'> Student not found</div>
                    }
                </div>
                {this.state.errorMessage != null && this.state.errorMessage.length > 0 &&
                    <div className='text-[#FF0000] my-4'>
                        {this.state.errorMessage}
                    </div>
                }

                {!this.state.needData && this.props.isEdit &&
                    <div className='text-right mt-2'>
                        <button type="button" className=" border-[1px] rounded-md border-[#000000] px-10 py-2" onClick={this.props.cancel}>Cancel</button>
                        <button type="button" className=" border-[1px] rounded-md border-[#4f46D9] px-10 py-2 ml-5 bg-[#4f46D9] text-white" onClick={() => this.onSubmit(this.props.id)}>Save the changes</button>
                    </div>
                }

                {this.state.needData &&
                    <div className='text-center my-10'>
                        <div role="status">
                            <svg aria-hidden="true" className="inline w-32 h-32 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }

            </>


        );
    }



}
export default StudentListingDetails;