import React from "react";
import { MicrosoftURL } from "../objects";
import Header from "../components/Header";
import { Link } from "react-router-dom";

const LoginPage = () => {
    return (
        <>
               <div className="w-[100%]">

               <div className="w-[100%] bg-[#4f46D9] px-3 py-2 text-center h-[120px]">


<Link to={'/'} className="float-left"><img src={window.location.origin + require('../assets/icas.png').replace('./', '/')} className='inline h-[100px] bg-white' title="ICAS" alt="ICAS"></img></Link>





<span className="text-white float-right mt-10 mr-10">
<a href={MicrosoftURL} className="inline-block px-1 group" data-title={'Login'}>
                    Login




                </a>


</span>



</div>

            </div>

        </>
    );
};

export default LoginPage;