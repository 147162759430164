import axios from 'axios';
import saveAs from 'file-saver';
import * as React from 'react';
import { BASEAPI, BaseError, FileUploadRecord, FileUploadResult, dinfo } from '../objects';

interface EmailProps {
    identifier: string;
}

interface EmailState {
    html: string;
    needData: boolean;
}



class Email extends React.Component<EmailProps, EmailState> {

    public state: EmailState = {
        html: "",
        needData: true,
    };


    public componentDidMount() {
        console.log('Email componentDidMount');
        this.loadData();
    }

    public componentDidUpdate() {
        console.log('Email componentDidUpdate');
        this.loadData();
    }



    public loadData = async () => {

        if (this.state.needData) {
            axios.get<BaseError>(BASEAPI + `GetEmail?identifier=${this.props.identifier}`)
                .then(data => {
                    //if no errors
                    if (data.data.errorCode === 0) {
                        this.setState({
                            html: data.data.errorMessage,
                            needData: false
                        });
                    }
                });
        }



    }

    public render() {

        return (

            <div className="text-left p-3  " dangerouslySetInnerHTML={{ __html: this.state.html }}>

            </div>
        );
    }



}
export default Email;