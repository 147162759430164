
export const UserName: string = (document.getElementById('UserName') as HTMLInputElement)?.value as string;
export const UserId: string = (document.getElementById('UserId') as HTMLInputElement)?.value as string;
export const MicrosoftURL: string = (document.getElementById('MicrosoftURL') as HTMLInputElement).value as string;
export const LogoutURL: string = (document.getElementById('LogoutURL') as HTMLInputElement).value as string;


export const ALL:string = "U1R2D3N4-A5X6-E7L8-A9V0-E1I2R3T4IMID";


export const isATO: boolean = (<HTMLInputElement>document.getElementById('isATO')).value == "9";
export const isAdmin: boolean = (<HTMLInputElement>document.getElementById('isAdmin')).value == "99";

var baseapi = "/api/" as string

console.log("window.location.href", window.location.href);
if (window.location.href.indexOf(':3000') > 0) baseapi = 'https://localhost:7777/api/';

export const BASEAPI: string = baseapi;


export const dinfo = (data: any) => {
    if (window.location.href != null && window.location.href.indexOf("3000") > 0) {
      return JSON.stringify(data, null, "\t");
    }
    else {
      return "";
    }
  };

  export const todo = () => {
    alert('not implemented');
  };

  export interface BasePageList {
    pageNumber: number;
    totalPages: number;
    isFirstPage: boolean;
    isLastPage: boolean;
    count: number;
    totalCount: number;
  }

export interface StudentLine {
    id: number;
    identifier:string;
    status:string;
    organizationNumber:string;
    organisationName:string;

    firstName:string;
    lastName:string;
    email:string;
    studentType:string;
    startDate:string;
    provider:string;
    qualification:string;
    prefferedClassLocation:string;

    //runtime
    selected: boolean;
  }

  export interface StudentUpload extends StudentLine{
		employer :string;
        pathWay :string;
        pathWayComments :string;
        //workDepartment :string;
        workSpecialism :string;
        exceptionApproved :string;//note
        exceptionOutcome :string;//note
        startingTerm :string;

  }

  export interface Student extends StudentUpload {
    isConfirmEmployer:string; //flag

    education:string;
    university:string;
    universityOther:string;
    degreeSubject:string;
    degreeQualification:string;
    degreeDate:string;
    isPostGraduate:string;
    preferredName:string;
    salutation:string;
    birthDate       :string;
    workEmail:string;
    mobilePhone       :string;
    gender:string;
    genderSelf:string;
    transIdentity:string;
    disabled:string;
    ethnicity:string;
    learnerNumber:string;
    convicted:string;
    convictedDetails:string;
    bancrupcy:string;
    address1:string;
    address2:string;
    address3:string;

    city:string;

    postalCode:string;

    state:string;

    country:string;


    organisationid:string;
    membernumber:string;
    joinDate:string;
    leaveDate:string;
    originalInstitute:string;
    emergencyContactName:string;
    emergencyContactHomephonenumber:string;
    emergencyContactMobilephonenumber:string;
    emergencyContactRelationshiptostudent:string;

    middleName:string;
    school:string;
    postGraduate: string;
    organisationName:string;
    needsEmployer:boolean;

  }


  export interface StudentList extends BasePageList {
    students: StudentLine[];
  }
  export interface FileUploadRecord  {
    row: string;
    field:string;
    reason: string;
  }

  export interface FileUploadResult  {
    updated: FileUploadRecord[];
    created: FileUploadRecord[];
    failed: FileUploadRecord[];
    errorCode: number;
    errorMessage:string;
  }
  export interface BaseError {
    errorCode : number;
     errorMessage :string;
  }

  export interface SelectOption {
    value:string ;
    text:string ;
    selected:string ;
    displayOrder :number
  }

  export interface StudentUploadOptions {

    prefferedClassLocation : SelectOption[];
    provider : SelectOption[];
    qualification : SelectOption[];
    //workDepartment : SelectOption[];
    workSpecialism : SelectOption[];
    pathWay : SelectOption[];
  }

  export interface StudentFormOptions {

    qualification : SelectOption[];
    ethnicity : SelectOption[];
    gender : SelectOption[];
    university : SelectOption[];
    disabled : SelectOption[];
    countries : SelectOption[];
    accounts : SelectOption[];
    employers : SelectOption[];
    transIdentity : SelectOption[];
    //transIdCustomerIdType : SelectOption[];
    degreeQualification : SelectOption[];
    degreeSubject : SelectOption[];

  }


  export interface Organization {
    name : string;
    value : string;
  }

  export interface AddressList {

    items : Address[];
    errorCode: number;
    errorMessage:string;
  }

  export interface Address {

    id : string;
    type : string;
    text : string;
    highlight : string;
    description : string;
  }
  export interface AuditTrail {
    id: string;
    studentId :number;
    userName:  string;
    status : string;
    description:  string;
    changeDate:string;
  }

  export interface User {
    id: string;
    userId :string;
    name:  string;
    email:  string;
    account : string;
    isATO:  boolean;
    isAdmin:boolean;
  }


  export interface UserList extends BasePageList {
    users: User[];
  }

  export interface StudentList extends BasePageList {
    students: StudentLine[];
  }


  export const isPhone = (value: string) => {
    if (value!=null && value.length>0) {
    return /^[0-9\+]+$/.test(value);
    }
    return true;
}

/*
export const isName= (value: string) => {
  console.log( /^[a-zA-Z'-]+$/.test(value));
  return /^[a-zA-Z'-]+$/.test(value);
}
*/
export const isFullName= (value: string) => {
   console.log("isFullName", value, /^[a-zA-Z '-]+$/.test(value));
   if (value!=null && value.length>0) {
      return /^[a-zA-Z '-]+$/.test(value);
   }
   return true;

}
