import * as React from 'react';
import { Link } from 'react-router-dom'; //https://reactrouter.com/en/main/components/nav-link
import { BASEAPI, LogoutURL, Organization, UserId, UserName, dinfo, isAdmin } from '../objects';
import Account from './Account';
import axios from 'axios';


interface IHeaderProps {
  org: string;
  userId: string;
  isAdmin: boolean;
  callback: ((org: string, orgName: string) => void);
}

interface StudentFormState {
  needData: boolean;
  organizations: Organization[];
}

class Header extends React.Component<IHeaderProps, StudentFormState> {

  public state: StudentFormState = {
    needData: true,
    organizations: []

  }


  private GetData() {

    if (this.state.needData) {
      axios.get<Organization[]>(BASEAPI + `GetOrganizations?userId=${this.props.userId}&isAdmin=${this.props.isAdmin}`)
        .then(data => {
          this.setState({
            needData: false,
            organizations: data.data
          });

          if (data.data != null && data.data.length > 0 && !(this.props.org != null && this.props.org.length > 0)) {
            const org = data.data[0].value;
            const orgName = data.data[0].name;

            this.props.callback(org, orgName);
          }
        });
    }


  }

  public componentDidMount() {
    this.GetData();
  }
  public componentDidUpdate() {
    this.GetData();
  }

  public onChange = async (event: any) => {
    const value: string = event.target.value;

    var orgName = this.state.organizations.filter(x => x.value == value)[0].name;

    console.log("onChange", event.target.value);

    this.props.callback(value, orgName);
  }

  public render() {

    var url = '/';
    if (this.props.userId != UserId) url = '/admin';

    return (
      <React.Fragment>

        <div className="flex bg-[#4f46D9] px-3 py-2 text-center h-[120px] ">

<div className='w-1/3'>
              <Link to={url} className="float-left"><img src={window.location.origin + require('../assets/icas.png').replace('./', '/')} className='inline h-[100px] bg-white' title="ICAS" alt="ICAS"></img></Link>
              </div>

              <div className='w-1/3'>
          {this.state.organizations != null && this.state.organizations.length > 0 &&
            <>



              <select className='w-full bg-[#4f46D9] text-white text-2xl font-bold mt-10 custom-select w-auto text-ellipsis overflow-hidden ... '
                value={this.props.org} onChange={this.onChange}>
                {this.state.organizations.map((org: Organization, index: number) =>
                  <option key={'o' + index} value={org.value}>{org.name}</option>
                )}

              </select>
            </>
          }

</div>

          <span className="w-1/3 text-white text-right mt-10 mr-10">
          {dinfo(this.props.userId == UserId)}
            {this.props.userId == UserId &&
              <Account logoutURL={LogoutURL} name={UserName}/>
            }
            {this.props.userId != UserId &&
              <Account logoutURL={"/admin"}  name=''/>
            }
          </span>



        </div>
      </React.Fragment>
    );
  }
};


export default Header;