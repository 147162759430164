import React from "react";
import AccessPopup from "../components/AccessPopup";
import { UserId } from "../objects";

const Page404 = () => {
    return <>
    <h1 className="text-center auto text-[10rem]">404</h1>;
    <h2 className="text-center auto text-[5rem]">PAGE NOT FOUND</h2>;
    <h3 className="text-center auto text-[2rem]">The page you are looking for might have been removed, had it's name changed or is temporary unavailable.</h3>;

{UserId!=null}

<AccessPopup />

    </>
};

export default Page404;