import axios from 'axios';
import * as React from 'react';
import { BASEAPI, BaseError, StudentFormOptions, Student, dinfo, SelectOption, todo, isPhone, isFullName } from '../objects';
import StudentFormInfo from './StudentFormInfo';
import debounce from 'lodash.debounce';

//import Datetime from "react-datetime";
//import "react-datetime/css/react-datetime.css";
//import moment, { Moment } from "moment";
//import "moment/locale/en-gb";
import DateTimePicker from './DateTimePicker';
import Autocomplete from './Autocomplete';


interface StudentFormProps {
    identifier: string;


}

interface StudentFormState {
    needData: boolean;
    errorMessage: string;
    student: Student;
    agree: boolean;
    agreeLink: boolean;
    needOptions: boolean;
    options: StudentFormOptions | undefined;
    isSubmitted: boolean;
    //SubmittedMessage: string;

    commandprocessing: boolean;

}


class StudentForm extends React.Component<StudentFormProps, StudentFormState> {

    public state: StudentFormState = {
        errorMessage: "",
        needData: true,
        agree: false,
        agreeLink: false,
        student: {
            id: 0,
            identifier: "",
            status: "",
            organizationNumber: "",

            firstName: "",
            lastName: "",
            email: "",
            studentType: "",
            startDate: "",
            provider: "",
            qualification: "",
            prefferedClassLocation: "",
            employer: "",
            pathWay: "",
            pathWayComments: "",
            //workDepartment: "",
            workSpecialism: "",
            exceptionApproved: "",
            exceptionOutcome: "",
            startingTerm: "",
            selected: false,
            address1: "",
            address2: "",
            address3: "",
            bancrupcy: "",
            birthDate: "",
            city: "",
            convicted: "",
            convictedDetails: "",
            country: "",
            degreeDate: "",//
            degreeQualification: "",
            degreeSubject: "",
            disabled: "",
            education: "",
            emergencyContactHomephonenumber: "",
            emergencyContactMobilephonenumber: "",
            emergencyContactName: "",
            emergencyContactRelationshiptostudent: "",
            ethnicity: "",
            gender: "",
            genderSelf: "",
            transIdentity: "",
            isConfirmEmployer: "",
            isPostGraduate: "",
            joinDate: "",
            learnerNumber: "",
            leaveDate: "",
            membernumber: "",
            mobilePhone: "",
            organisationid: "",
            originalInstitute: "",
            postalCode: "",
            preferredName: "",
            salutation: "",
            state: "",
            university: "",
            universityOther: "",
            workEmail: "",
            middleName: "",
            organisationName: "",
            postGraduate: "",
            school: "",
            needsEmployer: false,
        },
        needOptions: true,
        options: undefined,
        isSubmitted: false,
        //SubmittedMessage: "",
        commandprocessing: false,
    };

    public getOptions = () => {
        if (this.state.needOptions) {
            this.setState({
                needOptions: false

            });

            axios.get<StudentFormOptions>(BASEAPI + `GetFormOptions?identifier=${this.props.identifier}`)
                .then(data => {
                    this.setState({
                        options: data.data,
                        needOptions: false

                    });
                });
        }
    };


    public test = () => {

        var n = Math.floor((Math.random() * 1000000) + 1);

        this.setState({
            student: {
                ...this.state.student,

                education: "University",
                university: "605340012",
                universityOther: "other",
                qualification: "605340001",
                degreeDate: "2023-12-12",
                workEmail: "email" + n + "@test.com",
                isConfirmEmployer: "Yes",

                firstName: "firstName" + n,
                middleName: "M" + n,
                lastName: "lastName" + n,
                preferredName: "p" + n,
                salutation: "Sir",
                email: "email" + n + "@test.com",
                birthDate: "2000-01-01",
                mobilePhone: "2+" + n,

                gender: "605340003",
                genderSelf: "not sure",
                disabled: "605340002",
                ethnicity: "605340014",
                learnerNumber: "9" + n,

                convicted: "No",
                bancrupcy: "No",
                address1: "address1 " + n,
                address2: "address2 " + n,
                address3: "address3 " + n,
                city: "city " + n,
                postalCode: "p" + n,
                state: "s" + n,
                country: "c7150594-c094-e811-a977-0022480031cc",

                organisationid: "fdc7f287-643e-e811-a887-00224800449b",
                membernumber: "55-" + n,
                joinDate: "2020-10-10",
                leaveDate: "2025-10-10",
                originalInstitute: "No",


                emergencyContactHomephonenumber: "h" + n,
                emergencyContactMobilephonenumber: "m",
                emergencyContactName: "name" + n,
                emergencyContactRelationshiptostudent: "other",
                degreeQualification: "605340023",
                degreeSubject: "605340046",

                transIdentity: "605340001",


                /*
                startDate: "",
                provider: "",
                prefferedClassLocation: "",
                pathWay: "",
                pathWayComments: "",
                workDepartment: "",
                workSpecialism: "",
                exceptionApproved: "",
                exceptionOutcome: "",
                startingTerm: "",
                selected: false,

                degreeQualification: "",
                degreeSubject: "",
                isPostGraduate: "",
       */





            }
        });
    };



    public goTo = async () => {

        const elements = document.querySelectorAll("span.error");
        if (elements != null && elements.length > 0 && elements[0] != null) {

            for (let i = 0; i < elements.length; i++) {
                if (elements[i] != null) {
                    var elem: HTMLSpanElement = elements[i] as HTMLSpanElement;
                    if ((elem != null && elem.innerText != null && elem.innerText.length > 0)) {
                        console.log('elem', elem);
                        elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        break;
                    }
                }

            }
        }
    };



    public getAge = (birthday: string) => {
        var dob = new Date(birthday);
        //calculate month difference from current date in time
        var month_diff = Date.now() - dob.getTime();

        //convert the calculated difference in date format
        var age_dt = new Date(month_diff);

        //extract year from date
        var year = age_dt.getUTCFullYear();

        //now calculate the age of the user
        var age = year - 1970;

        if (!isNaN(age)) return age + " years";
        return "";
    };

    private GetData() {
        if (this.state.needData && this.props.identifier != null && this.props.identifier.length > 0) {

            this.setState({
                needData: false,
            });

            axios.get<Student>(BASEAPI + `GetForm?identifier=${this.props.identifier}`)
                .then(data => {

                    console.log('GetForm', data);
                    if (data.data != null) {

                        if (!(data.data.isPostGraduate != null && data.data.isPostGraduate.length > 0)) {
                            data.data.isPostGraduate = "No";
                        }

                        this.setState({
                            needData: false,
                            student: data.data
                        });
                    }
                    else {


                    }
                });
        }

        if (this.state.options !== undefined && this.state.options !== null) {
            //all OK
        }
        else {
            this.getOptions();

        }

    }

    public componentDidMount() {
        this.GetData();
    }
    public componentDidUpdate() {
        this.GetData();
    }

    public setCustomValidity() {
        //var field1: HTMLFormElement = document.getElementById("learnerNumber") as HTMLFormElement;
        //field1.setCustomValidity('Please lengthen this text to 10 digits.');

        //var field2: HTMLFormElement = document.getElementById("birthDate") as HTMLFormElement;
        //field2.setCustomValidity('Invalid DOB, must be DD/MM/YYYY');

    }

    public SaveFormStudent = async () => {
        console.log("onSave");

        var f: HTMLFormElement = document.getElementById("testForm") as HTMLFormElement;

        this.setCustomValidity();

        /*
                        var field2: HTMLFormElement = document.getElementById("studenttype1") as HTMLFormElement;
                        field2.setCustomValidity('Please select one option to proceed.');
                */
        if (f != null && f.checkValidity()) {

            this.setState({
                errorMessage: "",
                commandprocessing: true,
            });

            axios.post<BaseError>(BASEAPI + 'SubmitFormStudent', this.state.student)
                .then(data => {
                    //if no errors
                    if (data.data.errorCode === 0) {

                        this.setState({
                            isSubmitted: true,
                            //SubmittedMessage: "Student Form Submited!"
                        });
                    }
                    else {
                        this.setState({
                            errorMessage: data.data.errorMessage
                        });
                    }
                }).finally(() => {
                    this.setState({
                        commandprocessing: false,
                    });
                }
                );
        }
        else {
            this.ShowValidations(f);

            this.goTo();
        }


    }



    public onSubmit = async (e: any) => {

        e.preventDefault();

        var field: HTMLFormElement = document.getElementById('agree') as HTMLFormElement;
        if (field != null) {
            field.required = true;
        }
        console.log("onSubmit");

        if (! this.state.commandprocessing) {
            this.SaveFormStudent();
        }
    }

    public onSubmitDebounce = debounce(this.onSubmit, 1000)

    private ShowValidationElement(elem: HTMLInputElement) {

        this.setCustomValidity();

        if (!elem.checkValidity()) {
            console.log("ShowValidationElement", elem.id, elem, elem.checkValidity());
        }

        var m = "";
        if (!elem.checkValidity()) {

            if (elem.id == "learnerNumber" && elem.validationMessage.indexOf('Please lengthen') >= 0) {
                m = 'Please lengthen this text to 10 digits.';
            }
            else
                if (elem.id == "birthDate" && elem.validationMessage.indexOf('Please lengthen') >= 0) {
                    m = 'Invalid DOB, must be DD/MM/YYYY';
                }
                else if (elem.id == "degreeDate" && elem.validationMessage.indexOf('Please lengthen') >= 0) {
                    m = 'Invalid field, must be DD/MM/YYYY';
                }
                else if (elem.id == "joinDate" && elem.validationMessage.indexOf('Please lengthen') >= 0) {
                    m = 'Invalid field, must be DD/MM/YYYY';
                }
                else if (elem.id == "leaveDate" && elem.validationMessage.indexOf('Please lengthen') >= 0) {
                    m = 'Invalid field, must be DD/MM/YYYY';
                }
                else if (elem.id == "agreeLink" && elem.validationMessage.indexOf('Please check this box if you want to proceed') >= 0) {
                    m = 'Please visit the Terms and Conditions if you want to proceed.';
                }
                else {
                    m = elem.validationMessage;
                }
        }
        else {
            m = "";
        }

        var field: HTMLFormElement = document.getElementById(elem.id + "-error") as HTMLFormElement;
        if (field != null) {
            field.textContent = m;
        }

        return m;
    }

    private ShowValidations(f: HTMLFormElement) {

        this.setCustomValidity();

        var M = "";
        Array.from(f.elements).forEach((input) => {
            //console.log(input);
            var elem: HTMLInputElement = input as HTMLInputElement;
            M += " " + this.ShowValidationElement(elem);

        });
        console.log(M);
    }
    /*
        public handleDateChange = async (id: string, date: string | Moment) => {

            var student = { ...this.state.student };

            moment.locale('en-GB');
            var value = moment(date).format("YYYY-MM-DD");

            var field: HTMLFormElement = document.getElementById(id) as HTMLFormElement;
            if (field != null) {
                console.log("handleDateChange", id, value);
                field.value = value;
                field.checkValidity();
            }


            this.setValue(id, value);

        }
    */

    public onChange = async (id: string, event: any) => {
        //event.preventDefault();
        console.log("onChange", id, event);
        //const name: string = id;
        const value: string = event.target.value;
        this.setValue(id, value);

    }

    public setValue = async (id: string, value: string) => {


        console.log("setValue", id, value);

        var student = { ...this.state.student };
        if (student != null) {
            switch (id) {
                case "firstName": value = value.replace(/[^a-zA-Z '-]+/, ''); if (isFullName(value)) student.firstName = value; break;
                case "lastName": value = value.replace(/[^a-zA-Z '-]+/, ''); if (isFullName(value)) student.lastName = value; break;
                case "email": student.email = value; break;
                case "employer": student.employer = value; break;
                case "studentType": student.studentType = value; break;
                case "startDate": student.startDate = value; break;
                case "provider": student.provider = value; break;
                case "qualification": student.qualification = value; break;
                case "degreeQualification": student.degreeQualification = value; break;
                case "pathWay": student.pathWay = value; break;
                case "pathWayComments": student.pathWayComments = value; break;
                //case "workDepartment": student.workDepartment = value; break;
                case "workSpecialism": student.workSpecialism = value; break;
                case "exceptionApproved": student.exceptionApproved = value; break;
                case "exceptionOutcome": student.exceptionOutcome = value; break;
                case "startingTerm": student.startingTerm = value; break;
                case "address1": student.address1 = value; break;
                case "address2": student.address2 = value; break;
                case "address3": student.address3 = value; break;
                case "bancrupcy": student.bancrupcy = value; break;
                case "birthDate": student.birthDate = value; break;
                case "city": student.city = value; break;
                case "convicted": student.convicted = (value === "Yes") ? "Yes" : "No"; break;
                case "convictedDetails": student.convictedDetails = value; break;
                case "country": student.country = value; break;
                case "degreeDate": student.degreeDate = value; break;//
                case "degreeQualification": student.degreeQualification = value; break;
                case "degreeSubject": student.degreeSubject = value; break;
                case "disabled": student.disabled = value; break;
                case "education": student.education = value; break;
                case "emergencyContactName": value = value.replace(/[^a-zA-Z '-]+/, ''); if (isFullName(value)) student.emergencyContactName = value; break;
                case "emergencyContactHomephonenumber": if (isPhone(value)) student.emergencyContactHomephonenumber = value; break;
                case "emergencyContactMobilephonenumber": if (isPhone(value)) student.emergencyContactMobilephonenumber = value; break;
                case "emergencyContactRelationshiptostudent": student.emergencyContactRelationshiptostudent = value; break;
                case "ethnicity": student.ethnicity = value; break;
                case "gender": student.gender = value; break;
                case "genderSelf": student.genderSelf = value; break;
                case "isConfirmEmployer": student.isConfirmEmployer = value; break;
                case "isPostGraduate": student.isPostGraduate = value; break;
                case "joinDate": student.joinDate = value; break;
                case "learnerNumber": student.learnerNumber = value; break;
                case "leaveDate": student.leaveDate = value; break;
                case "membernumber": student.membernumber = value; break;
                case "mobilePhone": if (isPhone(value)) student.mobilePhone = value; break;
                case "organisationid": student.organisationid = value; break;
                case "originalInstitute": student.originalInstitute = value; break;
                case "postalCode": student.postalCode = value; break;
                case "preferredName": value = value.replace(/[^a-zA-Z '-]+/, ''); if (isFullName(value)) student.preferredName = value; break;
                case "salutation": value = value.replace(/[^a-zA-Z '-]+/, ''); if (isFullName(value)) student.salutation = value; break;
                case "state": student.state = value; break;
                case "university": student.university = value; break;
                case "universityOther": student.universityOther = value; break;
                case "school": student.school = value; break;
                case "postGraduate": student.postGraduate = value; break;
                case "workEmail": student.workEmail = value; break;
                case "middleName": value = value.replace(/[^a-zA-Z '-]+/, ''); if (isFullName(value)) student.middleName = value; break;
                case "transIdentity": student.transIdentity = value; break;

            }

        }
        this.setState({
            student: student
        });

        console.log("setValue2", id, value);
        var input: HTMLInputElement = document.getElementById(id) as HTMLInputElement;
        if (input != null) {
            console.log("setValue3", id, value);
            this.ShowValidationElement(input);
        }

    }

    public onAgree = async (event: any) => {

        const id: string = event.target.id;
        const value: boolean = event.target.checked;

        var input: HTMLInputElement = document.getElementById(id) as HTMLInputElement;
        if (input != null && input.checkValidity()) {
            this.ShowValidationElement(input);
        }

        this.setState({
            agree: value
        });
    }
    public onAgreeLink = async () => {

        const id: string ='agreeLink';

        var input: HTMLInputElement = document.getElementById(id) as HTMLInputElement;
        if (input != null && input.checkValidity()) {
            this.ShowValidationElement(input);
        }

        this.setState({
            agreeLink: true
        });
    }



    public autocomplete = ((address: string, city: string, zip: string) => {
        console.log(address);
        var student = { ...this.state.student };

        var country = '';

        var t = this.state.options?.countries.filter(x => x.text == 'United Kingdom');
        console.log('country', t);
        if (t != null && t.length > 0 && t[0] != null) {
            console.log('country', t[0].value);
            country = t[0].value;
        }

        if (student != null) {
            student.address1 = address;
            student.address2 = "";
            student.address3 = "";
            student.state = "";
            student.city = city;
            student.postalCode = zip;
            student.country = country;
        }

        this.setState({
            student: student
        });
    });

    public render() {

        var selfdescribe = false;
        var t = this.state.options?.gender.filter(x => x.text == 'Prefer to self describe' && x.value == this.state.student?.gender);
        if (t != null && t.length > 0) {
            selfdescribe = true;
        }

        var isUniversityOther = false;

        var t = this.state.options?.university.filter(x => x.text == 'Other' && x.value == this.state.student?.university);
        if (t != null && t.length > 0) {
            isUniversityOther = true;
        }

        return (
            <>




                <div className="bg-[#F8F9FA] text-[#000000] p-6">

                    {dinfo(BASEAPI)}



                    {!this.state.isSubmitted && !this.state.needData && (this.state.student != null && this.state.student.identifier != null && this.state.student.identifier.length > 0) &&
                        <form id="testForm" noValidate className="needValidation" onSubmit={(e) => { e.preventDefault(); return false; }} >





                            <div className='bg-white rounded-md  text-left shadow-xl  p-5 mt-2 mb-5'>
                                <div className='w-full text-2xl px-2 py-2' onClick={() => this.test()}>
                                    <div className=' underline'>Verify your details</div>
                                    <div className='text-sm'>Your employer has supplied us with your name and email address, please check that this is correct</div>
                                </div>

                                <div className='xl:flex text-left px-2 pt-2'>
                                    <div className='w-full xl:w-1/2 px-2 py-1'>
                                        <label className='my-2 block'>First Name <span className="text-[#FF0000]">*</span></label>
                                        <input type="text" id="firstName" maxLength={50}
                                            value={this.state.student?.firstName}
                                            onChange={(e) => this.onChange('firstName', e)}
                                            className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                            placeholder='Enter first name' required={true} />
                                        <span id="firstName-error" className='error text-[#FF0000]'></span>
                                    </div>


                                    <div className='w-full xl:w-1/2 px-2 py-1'>
                                        <label className='my-2 block'>Last Name <span className="text-[#FF0000]">*</span></label>
                                        <input type="text" id="lastName" maxLength={50}
                                            value={this.state.student?.lastName}
                                            onChange={(e) => this.onChange('lastName', e)}
                                            className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter last name' required={true} />
                                        <span id="lastname-error" className='error text-[#FF0000]'></span>
                                    </div>


                                </div>

                                <div className='xl:flex text-left px-2 pt-2'>
                                    <div className='w-full xl:w-1/2 px-2 py-1'>
                                        <StudentFormInfo text="Please ensure you have provided us with 1 personal email and 1 work email if possible.
                                    If your main main address is a university email address please ensure you provide an alternative.
                                    You will be issued a student.icas email account at the end of registration."/>
                                        <label className='my-2 block'>Email Address <span className="text-[#FF0000]">*</span>
                                        </label>
                                        <input type="email" id="email" maxLength={100}
                                            value={this.state.student?.email}
                                            onChange={(e) => this.onChange('email', e)} required={true}
                                            className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter email address' />
                                        <span id="email-error" className='error text-[#FF0000]'></span>
                                    </div>
                                    <div className='w-full xl:w-1/2 px-2 py-1'>
                                        <label className='my-2 block'>Employer Name</label>
                                        <input type="text" id="employer" maxLength={6}
                                            value={this.state.student?.organisationName}
                                            onChange={(e) => this.onChange('employer', e)} readOnly={true}
                                            className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Employer Name' />
                                        <span id="organisation-error" className='error text-[#FF0000]'></span>
                                    </div>
                                </div>

                                <div className='xl:flex text-left px-2 pt-2'>

                                    {this.state.student?.needsEmployer &&
                                        <div className='w-full xl:w-1/2 px-2 py-1'>
                                            <label className='my-2 block'>What office do you work at? <span className="text-[#FF0000]">*</span></label>
                                            <select id="employer"
                                                value={this.state.student?.employer}
                                                onChange={(e) => this.onChange('employer', e)}
                                                className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                                <option value="">Select</option>
                                                {this.state.options?.employers.map((record: SelectOption, index: number) =>
                                                    <option key={'o1_' + index} value={record.value}>{record.text}</option>
                                                )}

                                            </select>
                                            <span id="employer-error" className='error text-[#FF0000]'></span>

                                        </div>
                                    }

                                    <div className='w-full xl:w-1/2 px-2 py-1'>{dinfo(this.state.student?.isConfirmEmployer)}
                                        <label className='my-2 block'>Can you confirm this organisation is your current employer? <span className="text-[#FF0000]">*</span></label>
                                        <div className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                            <label key={"isConfirmEmployer1"} htmlFor="isConfirmEmployer1">
                                                <input type="radio" id="isConfirmEmployer1" name="isConfirmEmployer"
                                                    value="Yes"
                                                    checked={this.state.student?.isConfirmEmployer == "Yes"}
                                                    onChange={(e) => this.onChange('isConfirmEmployer', e)}
                                                    className='ml-2 mr-2' />Yes
                                            </label>
                                            <label key={"isConfirmEmployer2"} htmlFor="isConfirmEmployer2">
                                                <input type="radio" id="isConfirmEmployer2" name="isConfirmEmployer"
                                                    value="No"
                                                    checked={this.state.student?.isConfirmEmployer == "No"}
                                                    onChange={(e) => this.onChange('isConfirmEmployer', e)}
                                                    className='ml-6 mr-2' />No
                                            </label>
                                        </div>
                                        <span id="isConfirmEmployer1-error" className='error text-[#00FF00]'></span>
                                        <span id="isConfirmEmployer2-error" className='error text-[#FF0000]'></span>
                                    </div>

                                </div>
                            </div>

                            {this.state.student?.isConfirmEmployer === "No" &&

                                <div className='text-center text-[#FF0000]'>
                                    Please refer back to your current training principal and do not proceed with completing this form.

                                </div>
                            }

                            {this.state.student?.isConfirmEmployer === "Yes" &&
                                <>
                                    <div className='bg-white rounded-md  text-left shadow-xl  p-5 mt-2 mb-5'>
                                        <div className='text-2xl px-2 py-2 underline'>
                                            Education Information
                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Did you attend a University, College or School? <span className="text-[#FF0000]">*</span></label>
                                                <select id="education"
                                                    value={this.state.student?.education}
                                                    onChange={(e) => this.onChange('education', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                                    <option value="">Select</option>
                                                    <option value="University">University</option>
                                                    <option value="College">College</option>
                                                    <option value="School">School</option>
                                                </select>
                                                <span id="education-error" className='error text-[#FF0000]'></span>
                                            </div>

                                            {this.state.student?.education == "University" &&
                                                <div className='w-full xl:w-1/2 px-2 py-1'>
                                                    <label className='my-2 block'>University Attended <span className="text-[#FF0000]">*</span></label>
                                                    <select id="university"
                                                        value={this.state.student?.university}
                                                        onChange={(e) => this.onChange('university', e)}
                                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                                        <option value="">Select</option>
                                                        {this.state.options?.university.map((record: SelectOption, index: number) =>
                                                            <option key={'o2_' + index} value={record.value}>{record.text}</option>
                                                        )}

                                                    </select>
                                                    <span className='text-sm'>If you cannot locate your University, please select Other</span>
                                                    <span id="university-error" className='error text-[#FF0000]'></span>

                                                </div>
                                            }

                                            {(this.state.student?.education == "College" || this.state.student?.education == "School") &&
                                                <div className='w-full xl:w-1/2 px-2 py-1'>
                                                    <label className='my-2 block'>{this.state.student?.education} Attended <span className="text-[#FF0000]">*</span></label>
                                                    <input type="text" id="school"
                                                        value={this.state.student?.school}
                                                        onChange={(e) => this.onChange('school', e)}
                                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder={'Enter ' + this.state.student?.education} required={true} />
                                                    <span id="school-error" className='error text-[#FF0000]'></span>
                                                </div>
                                            }



                                        </div>

                                        {(this.state.student?.education == "University" && isUniversityOther) &&
                                            <div className='xl:flex text-left px-2 pt-2'>
                                                <div className='w-full xl:w-1/2 px-2 py-1'>&nbsp;</div>
                                                <div className='w-full xl:w-1/2 px-2 py-1'>
                                                    <label className='my-2 block'>Other University Attended <span className="text-[#FF0000]">*</span></label>
                                                    <input type="text" id="universityOther"
                                                        value={this.state.student?.universityOther}
                                                        onChange={(e) => this.onChange('universityOther', e)}
                                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder={'Enter Other University'} required={true} />
                                                    <span id="universityOther-error" className='error text-[#FF0000]'></span>
                                                </div>
                                            </div>
                                        }


                                        {this.state.student?.education == "University" &&
                                            <>
                                                <div className='xl:flex text-left px-2 pt-2'>
                                                    <div className='w-full xl:w-1/2 px-2 py-1'>
                                                        <label className='my-2 block'>Undergraduate Degree Subject <span className="text-[#FF0000]">*</span></label>
                                                        <select id="degreeSubject"
                                                            value={this.state.student?.degreeSubject}
                                                            onChange={(e) => this.onChange('degreeSubject', e)}
                                                            className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                                            <option value="">Select</option>
                                                            {this.state.options?.degreeSubject.map((record: SelectOption, index: number) =>
                                                                <option key={'o3_' + index} value={record.value}>{record.text}</option>
                                                            )}

                                                        </select>
                                                        <span id="degreeSubject-error" className='error text-[#FF0000]'></span>
                                                    </div>

                                                    <div className='w-full xl:w-1/2 px-2 py-1'>
                                                        <label className='my-2 block'>Qualification Gained <span className="text-[#FF0000]">*</span></label>
                                                        <select id="degreeQualification"
                                                            value={this.state.student?.degreeQualification}
                                                            onChange={(e) => this.onChange('degreeQualification', e)}
                                                            className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                                            <option value="">Select</option>
                                                            {this.state.options?.degreeQualification.map((record: SelectOption, index: number) =>
                                                                <option key={'o4_' + index} value={record.value}>{record.text}</option>
                                                            )}

                                                        </select>
                                                        <span id="degreeQualification-error" className='error text-[#FF0000]'></span>
                                                    </div>

                                                </div>

                                                <div className='xl:flex text-left px-2 pt-2'>


                                                    <div className='w-full xl:w-1/2 px-2 py-1'>
                                                        <DateTimePicker id="degreeDate" value={this.state.student?.degreeDate} label="Date of Graduation" required={true} setValue={this.setValue} readonly={false} />
                                                    </div>
                                                    <div className='w-full xl:w-1/2 px-2 py-1'>
                                                        <label className='my-2 block'>Did you complete post graduate studies? <span className="text-[#FF0000]">*</span></label>
                                                        <div className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                                            <label key={"isPostGraduate1"} htmlFor="isPostGraduate1">
                                                                <input type="radio" id="isPostGraduate1" name="isPostGraduate" required={true}
                                                                    value="Yes"
                                                                    checked={this.state.student?.isPostGraduate === "Yes"}
                                                                    onChange={(e) => this.onChange('isPostGraduate', e)}
                                                                    className='ml-2 mr-2' />Yes
                                                            </label>
                                                            <label key={"isPostGraduate2"} htmlFor="isPostGraduate2">
                                                                <input type="radio" id="isPostGraduate2" name="isPostGraduate" required={true}
                                                                    value="No"
                                                                    checked={this.state.student?.isPostGraduate === "No"}
                                                                    onChange={(e) => this.onChange('isPostGraduate', e)}
                                                                    className='ml-6 mr-2' />No
                                                            </label>
                                                        </div>
                                                        <span id="isPostGraduate1-error" className='error text-[#00FF00]'></span>
                                                        <span id="isPostGraduate2-error" className='error text-[#FF0000]'></span>
                                                    </div>
                                                </div>

                                            </>
                                        }




                                        <div className='xl:flex text-left px-2 pt-2'>
                                            {/*
                                        <div className='w-full xl:w-1/2 px-2 py-1'>
                                            <label className='my-2 block'>Work Email Address <span className="text-[#FF0000]">*</span>
                                                <StudentFormInfo text="Please ensure you have provided us with 1 personal email and 1 work email if possible.
                                    If your main main address is a university email address please ensure you provide an alternative.
                                    You will be issued a student.icas email account at the end of registration."/>
                                            </label>
                                            <input type="email" id="workEmail"  maxLength={100}
                                                value={this.state.student?.workEmail}
                                                onChange={(e) => this.onChange('workEmail', e)}
                                                className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter work email address' required={true} />
                                            <span id="workEmail-error" className='error text-[#FF0000]'></span>
                                        </div>
*/}
                                            {this.state.student?.education == "University" && this.state.student?.isPostGraduate == "Yes" &&
                                                <div className='w-full xl:w-1/2 px-2 py-1'>
                                                    <label className='my-2 block'>Post Graduate Qualification Details <span className="text-[#FF0000]">*</span></label>
                                                    <input type="text" id="postGraduate"
                                                        value={this.state.student?.postGraduate}
                                                        onChange={(e) => this.onChange('postGraduate', e)}
                                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder={'Enter Post Graduate Qualification Details'} required={true} />
                                                    <span id="postGraduate-error" className='error text-[#FF0000]'></span>
                                                </div>
                                            }

                                        </div>

                                    </div>

                                    <div className='bg-white rounded-md  text-left shadow-xl  p-5 mt-2 mb-5'>
                                        <div className='text-2xl px-2 py-2 underline'>
                                            About You
                                        </div>

                                        <div className='text-2xl px-2 pt-2'>
                                            Name
                                        </div>
                                        <div className='px-2 pb-2 text-sm'>
                                            Please note this name will appear on your certificate and must match the name on your ID (required for assessments)
                                        </div>
                                        <div className='xl:flex text-left px-2 pt-2'>

                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <label className='my-2 block'>Salutation </label>

                                                <select id="salutation"
                                                    value={this.state.student?.salutation}
                                                    onChange={(e) => this.onChange('salutation', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                                    <option value="">Select</option>
                                                    <option key='o5_1' value='Miss'>Miss</option>
                                                    <option key='o5_2' value='Mrs'>Mrs</option>
                                                    <option key='o5_3' value='Mr'>Mr</option>
                                                    <option key='o5_4' value='Ms'>Ms</option>
                                                    <option key='o5_5' value='Mx'>Mx</option>
                                                    <option key='o5_6' value='Dr'>Dr</option>
                                                    <option key='o5_7' value='Lady'>Lady</option>
                                                    <option key='o5_8' value='Lord'>Lord</option>
                                                    <option key='o5_9' value='Prof'>Prof</option>
                                                    <option key='o5_10' value='Rev'>Rev</option>
                                                    <option key='o5_11' value='Sir'>Sir</option>
                                                </select>
                                                <span id="salutation-error" className='error text-[#FF0000]'></span>
                                            </div>

                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <label className='my-2 block'>First Name <span className="text-[#FF0000]">*</span></label>
                                                <input type="text" id="firstName2" maxLength={50}
                                                    value={this.state.student?.firstName}
                                                    onChange={(e) => this.onChange('firstName', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter first name' required={true} />
                                                <span id="firstname2-error" className='error text-[#FF0000]'></span>
                                            </div>

                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <label className='my-2 block'>Middle Name </label>
                                                <input type="text" id="middleName"
                                                    value={this.state.student?.middleName}
                                                    onChange={(e) => this.onChange('middleName', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter middle name' />
                                                <span id="middlename-error" className='error text-[#FF0000]'></span>
                                            </div>

                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <label className='my-2 block'>Last Name <span className="text-[#FF0000]">*</span></label>
                                                <input type="text" id="lastName2" maxLength={50}
                                                    value={this.state.student?.lastName}
                                                    onChange={(e) => this.onChange('lastName', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter last name' required={true} />
                                                <span id="lastname2-error" className='error text-[#FF0000]'></span>
                                            </div>



                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>






                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <label className='my-2 block'>Preferred Name </label>
                                                <input type="text" id="preferredName"
                                                    value={this.state.student?.preferredName}
                                                    onChange={(e) => this.onChange('preferredName', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter preferred name' />
                                                <span id="preferredname-error" className='error text-[#FF0000]'></span>
                                            </div>
                                        </div>


                                        <div className='text-2xl px-2 pt-5'>
                                            Contact details
                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>



                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                {/*
                                            <label className='my-2 block'>DOB <span className="text-[#FF0000]">*</span></label>
                                            <div className='flex flex-row border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                                <input type="date" id="birthDate"
                                                    value={this.state.student?.birthDate}
                                                    onChange={(e) => this.onChange('birthDate', e)}
                                                    className='inline flex-1 mr-2' placeholder='DD/MM/YYYY' required={true} />

                                                <div className='inline-block text-right'>
                                                    <div className=' bg-[#000000] text-white inline-block p-1 rounded-md text-right'>{this.getAge(this.state.student?.birthDate)}</div>
                                                </div>
                                            </div>
                                            <span id="birthDate-error" className='error text-[#FF0000]'></span>
*/}
                                                <DateTimePicker id="birthDate" value={this.state.student?.birthDate} label="DOB" required={true} setValue={this.setValue} readonly={false} />

                                            </div>


                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <label className='my-2 block'>Mobile Number  <span className="text-[#FF0000]">*</span></label>
                                                <input type="phone" id="mobilePhone"
                                                    value={this.state.student?.mobilePhone} maxLength={14} minLength={10}
                                                    onChange={(e) => this.onChange('mobilePhone', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter mobile number' required={true} />
                                                <span id="mobilePhone-error" className='error text-[#FF0000]'></span>
                                            </div>



                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <StudentFormInfo text="Please ensure you have provided us with 1 personal email and 1 alternative work email if possible.
                                    If your main main address is a university email address please ensure you provide an alternative.
                                    You will be issued a student.icas email account at the end of registration."/>
                                                <label className='my-2 block'>Alternative email address {/* <span className="text-[#FF0000]">*</span>  required={true} */}
                                                </label>
                                                <input type="email" id="workEmail" maxLength={100}
                                                    value={this.state.student?.workEmail}
                                                    onChange={(e) => this.onChange('workEmail', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter alternative email address' />
                                                <span id="workEmail-error" className='error text-[#FF0000]'></span>
                                            </div>


                                        </div>


                                        <div className='text-2xl px-2 pt-5'>
                                            Diversity questions
                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <label className='my-2 block'>What best describe your gender? <span className="text-[#FF0000]">*</span></label>

                                                <select id="gender"
                                                    value={this.state.student?.gender}
                                                    onChange={(e) => this.onChange('gender', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                                    <option value="">Select</option>
                                                    {this.state.options?.gender.map((record: SelectOption, index: number) =>
                                                        <option key={'o6_' + index} value={record.value}>{record.text}</option>
                                                    )}

                                                </select>
                                                <span id="gender-error" className='error text-[#FF0000]'></span>

                                            </div>

                                            {selfdescribe &&
                                                <div className='w-full xl:w-1/4 px-2 py-1'>
                                                    <label className='my-2 block'>Prefer to self describe? <span className="text-[#FF0000]">*</span></label>
                                                    <input type="text" id="genderSelf"
                                                        value={this.state.student?.genderSelf}
                                                        onChange={(e) => this.onChange('genderSelf', e)}
                                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                        placeholder='Prefer not to say' required={true} />
                                                    <span id="genderSelf-error" className='error text-[#FF0000]'></span>
                                                </div>
                                            }

                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <label className='my-2 block'>Is your gender identity the same as the sex you were assigned at birth? <span className="text-[#FF0000]">*</span>                                                </label>
                                                <select id="transIdentity"
                                                    value={this.state.student?.transIdentity}
                                                    onChange={(e) => this.onChange('transIdentity', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                                    <option value="">Select</option>
                                                    {this.state.options?.transIdentity.map((record: SelectOption, index: number) =>
                                                        <option key={'o7_' + index} value={record.value}>{record.text}</option>
                                                    )}

                                                </select>
                                                <span id="transIdentity-error" className='error text-[#FF0000]'></span>

                                            </div>

                                        </div>


                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <StudentFormInfo text="A person has a disability if they have a physical or mental impairment, and the impairment has a substantial and long-term
                                adverse effect on their ability to carry out normal day-to-day activities (Equality Act, 2010, Section 6)"/>

                                                <label className='my-2 block'>Do you consider yourself disabled? <span className="text-[#FF0000]">*</span></label>



                                                <select id="disabled"
                                                    value={this.state.student?.disabled}
                                                    onChange={(e) => this.onChange('disabled', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                                    <option value="">Select</option>
                                                    {this.state.options?.disabled.map((record: SelectOption, index: number) =>
                                                        <option key={'o8_' + index} value={record.value}>{record.text}</option>
                                                    )}

                                                </select>
                                                <div className='text-sm'>Please remember to complete a Reasonable Adjustments application if applicable</div>
                                                <span id="disabled-error" className='error text-[#FF0000]'></span>

                                            </div>

                                            <div className='w-full xl:w-1/4 px-2 py-1'>
                                                <label className='my-2 block'>What is your ethnicity? <span className="text-[#FF0000]">*</span></label>
                                                <select id="ethnicity"
                                                    value={this.state.student?.ethnicity}
                                                    onChange={(e) => this.onChange('ethnicity', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                                    <option value="">Select</option>
                                                    {this.state.options?.ethnicity.map((record: SelectOption, index: number) =>
                                                        <option key={'o9_' + index} value={record.value}>{record.text}</option>
                                                    )}

                                                </select>
                                                <span id="ethnicity-error" className='error text-[#FF0000]'></span>
                                            </div>
                                        </div>

                                        {this.state.student?.studentType == 'Apprentice' &&
                                            <>
                                                <div className='text-2xl px-2 pt-5'>
                                                    Your Unique Learner Number
                                                </div>

                                                <div className='flex text-left px-2 pt-2'>
                                                    <div className='w-full xl:w-1/4 px-2 py-1'>
                                                        <label className='my-2 block'>Unique Learner Number  <span className="text-[#FF0000]">*</span></label>
                                                        <input type="text" id="learnerNumber" minLength={10} maxLength={10}
                                                            value={this.state.student?.learnerNumber}
                                                            onChange={(e) => this.onChange('learnerNumber', e)}
                                                            className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter Unique Learner Number'
                                                            required={true} />
                                                        <span id="learnerNumber-error" className='error text-[#FF0000]'></span>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className='bg-white rounded-md  text-left shadow-xl  p-5 mt-2 mb-5'>
                                        <div className='text-2xl px-2 py-2 underline'>
                                            Eligibility Information
                                        </div>
                                        <div className='text-sm'>
                                            There may be a delay in processing your application if you have answered yes to either of these questions.
                                        </div>
                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <StudentFormInfo text='Please select “Yes” if you have any convictions that are not yet spent under the Rehabilitation of Offenders Act 1974. The term ‘convictions’ is used to refer to any sentence or disposal issued by a court.<br/>
If all your convictions are spent, you can select "No".<br/>
Further information on when a conviction is spent can be found here: <a href="https://www.mygov.scot/convictions-higher-level-disclosures/spent-convictions" class="underline text-[#0000FF]" target="new">https://www.mygov.scot/convictions-higher-level-disclosures/spent-convictions</a> (Scotland)
or here: <a href="https://www.gov.uk/tell-employer-or-college-about-criminal-record" class="underline text-[#0000FF]"  target="new">https://www.gov.uk/tell-employer-or-college-about-criminal-record</a> (England & Wales)' />
                                                <label className='my-2 block'>Have you ever been convicted of any criminal offence? <span className="text-[#FF0000]">*</span>
                                                </label>
                                                <div className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                                    <label key={"convicted1"} htmlFor="convicted1">
                                                        <input type="radio" id="convicted1" name="convicted"
                                                            value="Yes"
                                                            checked={this.state.student?.convicted === "Yes"}
                                                            onChange={(e) => this.onChange('convicted', e)} required={this.state.student?.convicted !== 'Yes' && this.state.student?.convicted !== 'No'}
                                                            className='ml-2 mr-2' />Yes
                                                    </label>
                                                    <label key={"convicted2"} htmlFor="convicted2">
                                                        <input type="radio" id="convicted2" name="convicted"
                                                            value="No"
                                                            checked={this.state.student?.convicted === "No"}
                                                            onChange={(e) => this.onChange('convicted', e)} required={this.state.student?.convicted !== 'Yes' && this.state.student?.convicted !== 'No'}
                                                            className='ml-6 mr-2' />No
                                                    </label>
                                                </div>
                                                {(this.state.student?.convicted !== 'Yes' && this.state.student?.convicted !== 'No') &&
                                                    <span id="convicted1-error" className='error text-[#F00F00]'></span>
                                                }
                                                {/*<span id="convicted2-error" className='error text-[#FF0000]'></span>*/}
                                            </div>

                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <StudentFormInfo text="Please select yes if you are currently, or have previously been, subject to formal proceedings in relation to non-payment of debts, including bankruptcy, protected trust deed, and IVA." />
                                                <label className='my-2 block'>Have you ever been the subject of bankruptcy proceedings? <span className="text-[#FF0000]">*</span>

                                                </label>
                                                <div className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                                    <label key={"bancrupcy1"} htmlFor="bancrupcy1">
                                                        <input type="radio" id="bancrupcy1" name="bancrupcy"
                                                            value="Yes"
                                                            checked={this.state.student?.bancrupcy === "Yes"}
                                                            onChange={(e) => this.onChange('bancrupcy', e)} required={this.state.student?.bancrupcy !== 'Yes' && this.state.student?.bancrupcy !== 'No'}
                                                            className='ml-2 mr-2' />Yes
                                                    </label>
                                                    <label key={"bancrupcy2"} htmlFor="bancrupcy2">
                                                        <input type="radio" id="bancrupcy2" name="bancrupcy"
                                                            value="No"
                                                            checked={this.state.student?.bancrupcy === "No"}
                                                            onChange={(e) => this.onChange('bancrupcy', e)} required={this.state.student?.bancrupcy !== 'Yes' && this.state.student?.bancrupcy !== 'No'}
                                                            className='ml-6 mr-2' />No
                                                    </label>
                                                </div>
                                                {(this.state.student?.bancrupcy !== 'Yes' && this.state.student?.bancrupcy !== 'No') &&
                                                    <span id="bancrupcy1-error" className='error text-[#FF0000]'></span>
                                                }
                                                {/*<span id="bancrupcy2-error" className='error text-[#FF0000]'></span>*/}
                                            </div>
                                        </div>
                                        {this.state.student?.convicted === 'Yes' &&
                                            <div className='xl:flex text-left px-2 pt-2'>
                                                <div className='w-full xl:w-1/2 px-2 py-1'>
                                                    <label className='my-2 block'>Criminal Conviction Details <span className="text-[#FF0000]">*</span></label>
                                                    <textarea id="convictedDetails"
                                                        value={this.state.student?.convictedDetails}
                                                        onChange={(e) => this.onChange('convictedDetails', e)}
                                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                        placeholder='Enter criminal conviction details' required={true} />
                                                    <span id="convictedDetails-error" className='error text-[#FF0000]'></span>
                                                </div>


                                            </div>
                                        }
                                    </div>

                                    <div className='bg-white rounded-md  text-left shadow-xl  p-5 mt-2 mb-5'>
                                        <div className='text-2xl px-2 py-2 underline'>
                                            Current Home Address
                                        </div>
                                        <div className='flex text-left px-2 pt-2'>
                                            <div className='w-full px-2 py-1'>
                                                <Autocomplete Url="https://api.addressy.com" Key="EP49-UD77-PW88-ZK32" callback={this.autocomplete} />
                                            </div>
                                        </div>

                                        <div className='flex text-left px-2 pt-2'>
                                            <div className='w-full px-2 py-1'>
                                                <label className='my-2 block'>Line 1 <span className="text-[#FF0000]">*</span></label>
                                                <input type="text" id="address1"
                                                    value={this.state.student?.address1}
                                                    onChange={(e) => this.onChange('address1', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter line 1' required={true} />
                                                <span id="address1-error" className='error text-[#FF0000]'></span>
                                            </div>
                                        </div>
                                        <div className='flex text-left px-2 pt-2'>

                                            <div className='w-full px-2 py-1'>
                                                <label className='my-2 block'>Line 2</label>
                                                <input type="text" id="address2"
                                                    value={this.state.student?.address2}
                                                    onChange={(e) => this.onChange('address2', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter line 2' />
                                                <span id="address2-error" className='error text-[#FF0000]'></span>
                                            </div>
                                        </div>

                                        <div className='flex text-left px-2 pt-2'>

                                            <div className='w-full px-2 py-1'>
                                                <label className='my-2 block'>Line 3</label>
                                                <input type="text" id="address3"
                                                    value={this.state.student?.address3}
                                                    onChange={(e) => this.onChange('address3', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter line 3' />
                                                <span id="address3-error" className='error text-[#FF0000]'></span>
                                            </div>

                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>City <span className="text-[#FF0000]">*</span></label>
                                                <input type="text" id="city"
                                                    value={this.state.student?.city}
                                                    onChange={(e) => this.onChange('city', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter your city' required={true} />
                                                <span id="city-error" className='error text-[#FF0000]'></span>
                                            </div>

                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Postal Code/ Zip Code <span className="text-[#FF0000]">*</span></label>
                                                <input type="text" id="postalCode"
                                                    value={this.state.student?.postalCode}
                                                    onChange={(e) => this.onChange('postalCode', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter your postal code / zip code' required={true} />
                                                <span id="postalCode-error" className='error text-[#FF0000]'></span>
                                            </div>

                                        </div>


                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>County/State</label>

                                                <input type="text" id="state"
                                                    value={this.state.student?.state}
                                                    onChange={(e) => this.onChange('state', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'
                                                    placeholder='Enter your county / state' />
                                                <span id="state-error" className='error text-[#FF0000]'></span>

                                                {/*
                                    <select id="state"
                                        value={this.state.student?.state}
                                        onChange={(e) => this.onChange('state', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                        <option value="">Select</option>
                                        this.state.options?.state.map((record: SelectOption, index: number) =>
                                        <option key={'o' + index} value={record.value}>{record.text}</option>

                                    </select>
                                */}
                                                <span id="state-error" className='error text-[#FF0000]'></span>
                                            </div>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Current Home Country <span className="text-[#FF0000]">*</span></label>
                                                <select id="country"
                                                    value={this.state.student?.country}
                                                    onChange={(e) => this.onChange('country', e)} required={true}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                                    <option value="">Select</option>
                                                    {/*<option value="2f160594-c094-e811-a977-0022480031cc">UK</option>*/}
                                                    {this.state.options?.countries.map((record: SelectOption, index: number) =>
                                                        <option key={'o10_' + index} value={record.value}>{record.text}</option>
                                                    )}

                                                </select>
                                                <span className='text-sm'>Please note that this is the Country you will reside in whilst studying</span>
                                                <span id="country-error" className='error text-[#FF0000] block'></span>
                                            </div>
                                        </div>
                                    </div>




                                    <div className='bg-white rounded-md  text-left shadow-xl  p-5 mt-2 mb-5'>
                                        <div className='text-2xl px-2 py-2 underline'>
                                            Other Professional Bodies Memberships
                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Professional Body </label>
                                                <select id="organisationid"
                                                    value={this.state.student?.organisationid}
                                                    onChange={(e) => this.onChange('organisationid', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                                    <option value="">Select</option>
                                                    {this.state.options?.accounts.map((record: SelectOption, index: number) =>
                                                        <option key={'o11_' + index} value={record.value}>{record.text}</option>
                                                    )}
                                                </select>
                                                <span id="organisationid-error" className='error text-[#FF0000]'></span>
                                            </div>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Membership Number</label>
                                                <input type="text" id="membernumber"
                                                    value={this.state.student?.membernumber}
                                                    onChange={(e) => this.onChange('membernumber', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter number' />
                                                <span id="membernumber-error" className='error text-[#FF0000]'></span>
                                            </div>
                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                {/*
                                            <label className='my-2 block'>Join Date</label>
                                            <input type="date" id="joinDate"
                                                value={this.state.student?.joinDate}
                                                onChange={(e) => this.onChange('joinDate', e)}
                                                className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='DD/MM/YYYY' />
                                            <span id="joinDate-error" className='error text-[#FF0000]'></span>
*/}
                                                <DateTimePicker id="joinDate" value={this.state.student?.joinDate} label="Join Date" required={false} setValue={this.setValue} readonly={false} />
                                            </div>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                {/*
                                            <label className='my-2 block'>Leave Date</label>
                                            <input type="date" id="leaveDate"
                                                value={this.state.student?.leaveDate}
                                                onChange={(e) => this.onChange('leaveDate', e)}
                                                className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='DD/MM/YYYY' />
                                            <span id="leaveDate-error" className='error text-[#FF0000]'></span>
*/}
                                                <DateTimePicker id="leaveDate" value={this.state.student?.leaveDate} label="Leave Date" required={false} setValue={this.setValue} readonly={false} />

                                            </div>
                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Is the professional body you have entered your original institute?</label>
                                                <div className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2'>
                                                    <label key={"originalInstitute1"} htmlFor="originalInstitute1">
                                                        <input type="radio" id="originalInstitute1" name="originalInstitute"
                                                            value="Yes"
                                                            checked={this.state.student?.originalInstitute === "Yes"}
                                                            onChange={(e) => this.onChange('originalInstitute', e)}
                                                            className='ml-2 mr-2' />Yes
                                                    </label>
                                                    <label key={"originalInstitute2"} htmlFor="originalInstitute2">
                                                        <input type="radio" id="originalInstitute2" name="originalInstitute"
                                                            value="No"
                                                            checked={this.state.student?.originalInstitute === "No"}
                                                            onChange={(e) => this.onChange('originalInstitute', e)}
                                                            className='ml-6 mr-2' />No
                                                    </label>
                                                </div>
                                                <span id="originalInstitute1-error" className='error text-[#00FF00]'></span>
                                                <span id="originalInstitute2-error" className='error text-[#FF0000]'></span>
                                            </div>

                                        </div>


                                    </div>




                                    <div className='flexbg-white rounded-md  text-left shadow-xl  p-5 mt-2 mb-5'>
                                        <div className='text-2xl px-2 py-2 underline'>
                                            Emergency Contact Detail
                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Emergency Contact Name <span className="text-[#FF0000]">*</span></label>
                                                <input type="text" id="emergencyContactName"
                                                    value={this.state.student?.emergencyContactName} maxLength={50}
                                                    onChange={(e) => this.onChange('emergencyContactName', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter contact name' required={true} />
                                                <span id="emergencyContactName-error" className='error text-[#FF0000]'></span>
                                            </div>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Emergency Contact Phone Number <span className="text-[#FF0000]">*</span></label>
                                                <input type="text" id="emergencyContactHomephonenumber" maxLength={14} minLength={10}
                                                    value={this.state.student?.emergencyContactHomephonenumber}
                                                    onChange={(e) => this.onChange('emergencyContactHomephonenumber', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter contact number' required={true} />
                                                <span id="emergencyContactHomephonenumber-error" className='error text-[#FF0000]'></span>
                                            </div>
                                        </div>

                                        <div className='xl:flex text-left px-2 pt-2'>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Emergency Contact Alternative Phone Number</label>
                                                <input type="text" id="emergencyContactMobilephonenumber" maxLength={14} minLength={10}
                                                    value={this.state.student?.emergencyContactMobilephonenumber}
                                                    onChange={(e) => this.onChange('emergencyContactMobilephonenumber', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter number' />
                                                <span id="emergencyContactMobilephonenumber-error" className='error text-[#FF0000]'></span>
                                            </div>
                                            <div className='w-full xl:w-1/2 px-2 py-1'>
                                                <label className='my-2 block'>Emergency Contact Relationship To Student <span className="text-[#FF0000]">*</span></label>

                                                <input type="text" id="emergencyContactRelationshiptostudent"
                                                    value={this.state.student?.emergencyContactRelationshiptostudent}
                                                    onChange={(e) => this.onChange('emergencyContactRelationshiptostudent', e)}
                                                    className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' placeholder='Enter relationship' required={true} />
                                                <span id="emergencyContactRelationshiptostudent-error" className='error text-[#FF0000]'></span>
                                                {/*
                                    <select id="emergencyContactRelationshiptostudent"
                                        value={this.state.student?.emergencyContactRelationshiptostudent}
                                        onChange={(e) => this.onChange('emergencyContactRelationshiptostudent', e)}
                                        className='w-full border-[1px] border-[#DDDDDD] bg-[#F8F9FA] rounded-md p-2' required={true}>
                                        <option value="">Select</option>
                                        *his.state.options?.emergencyContactRelationshiptostudent.map((record: SelectOption, index: number) =>
                                            <option key={'o' + index} value={record.value}>{record.text}</option>

                                    </select>
                                    <span id="emergencyContactRelationshiptostudent-error" className='error text-[#FF0000]'></span>      )*/}
                                            </div>
                                        </div>


                                    </div>




                                    <div className='text-left mt-2 p-5'>
<div className='font-bold underline'>Conditions of becoming a CA Student Member</div>

<div className='mt-3'>
<a href="https://advantage.icas.com/shared/CA23/Student%20registration%20terms%20and%20conditions.html"
                                            onClick={()=>this.onAgreeLink()}
                                            className="text-[#4f46D9] underline" target="_new">You must open this page to read and review the Conditions of becoming a CA Student Member before completing your registration.</a>
</div>
<div className='mt-5'>
                                        <label key={"agree"} htmlFor="agree">
                                            <input type="checkbox" id="agree" name="agree"
                                                value="Yes"
                                                checked={this.state.agree}
                                                onChange={this.onAgree}
                                                className='mr-2' required={true} />

                                            Please confirm you agree to the <span className='font-bold'>Conditions of becoming a CA Student Member</span>


                                        </label>
                                        <span id="agree-error" className='error text-[#FF0000] block'></span>

                                        <input type="checkbox" id="agreeLink" name="agreeLink"
                                                value="Yes"
                                                checked={this.state.agreeLink}

                                                className='hidden ml-2 mr-2' required={true} />


                                        <span id="agreeLink-error" className='error text-[#FF0000] block'></span>
                                        </div>
                                    </div>
                                </>
                            }
                            {this.state.errorMessage != null && this.state.errorMessage.length > 0 &&
                                <div className='text-center text-[#FF0000] my-4'>
                                    {this.state.errorMessage}
                                </div>
                            }

                            {!this.state.needData && this.state.student.isConfirmEmployer === "Yes" &&
                                <div className='text-right mt-2 p-5'>
                                    {/*<button type="button" className=" border-[1px] rounded-md border-[#000000] px-10 py-2 float-left" onClick={() => this.onSave()}>Save</button>*/}

                                    <button type="button" className=" border-[1px] rounded-md border-[#4f46D9] px-10 py-2 ml-5 bg-[#4f46D9] text-white" onClick={(e) => this.onSubmitDebounce(e)}>Complete your registration

                                        {this.state.commandprocessing &&
                                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-3 text-[#d52162]  animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                        }


                                    </button>
                                </div>
                            }

                            {this.state.needData &&
                                <div className='text-right my-10'>
                                    <div role="status">
                                        <svg aria-hidden="true" className="inline w-32 h-32 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }

                        </form>
                    }
                    {!this.state.isSubmitted && !(this.state.student != null && this.state.student.identifier != null && this.state.student.identifier.length > 0) &&
                        <div className='text-center'> Student not found</div>
                    }

                    {this.state.isSubmitted &&
                        <div className='text-center'>
                            {/*this.state.SubmittedMessage*/}

                            <div>
                                Registration form submitted.
                            </div>
                            <div className='mt-4'>
                                Please check your personal email for the next steps (remember to check your spam folder.)
                            </div>
                            <div className='mt-4'>
                                If you have not recieved an email over the next few hours, please contact <a href="mailto:students@icas.com" className='underline'>students@icas.com</a>
                            </div>

                        </div>

                    }
                </div>



                {/*dinfo(this.state.options)*/}  {/*dinfo(this.state)*/}


            </>
        );
    }



}
export default StudentForm;