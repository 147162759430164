import axios from 'axios';
import saveAs from 'file-saver';
import * as React from 'react';
import { MicrosoftURL, UserId } from '../objects';

interface AccountProps {
    logoutURL: string,
    name: string,

}

interface AccountState {
    showhide: boolean,
}



class Account extends React.Component<AccountProps, AccountState> {

    public state: AccountState = {
        showhide: false,
    };



    public render() {

        if (UserId != null && UserId.length > 0) {

            var username = this.props.name;

            var impersonate: boolean = false;
            if (!(this.props.name != null && this.props.name.length > 0)) {
                const Impersonate: string = (document.getElementById('Impersonate') as HTMLInputElement).value as string;
                console.log("Impersonate", Impersonate);
                username = Impersonate;
                impersonate = true;
            }

            if (!(username != null && username.length > 0)) {
                window.location.href = "/admin";
            }

            console.log('impersonate', impersonate);

            return (

                <button className="inline-block px-1 group" onClick={this.handleShowHide} data-title={'Info'}>
                    <>

                        {username != null && username.length > 0 &&
                            <span>{username}</span>
                        }

                        <div className={"hidden group-hover:block absolute z-10 border-[#DDDDDD] border-[1px] w-[100px] mt-0" + (impersonate ? ' w-[200px]':' w-[100px]')}>




                            <div className="relative bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-0 sm:max-w-lg sm:w-full">
                                <div className="bg-white">

                                    <div className="text-center ">

                                        {!impersonate &&
                                            <div className='cursor-pointer p-2 hover:bg-[#DDDDDD]'>
                                                <a href={this.props.logoutURL} className='text-[#4f46D9]'>Logout</a>
                                            </div>
                                        }
                                        {impersonate && <div className='cursor-pointer p-2 hover:bg-[#DDDDDD]'>
                                            <a href={this.props.logoutURL} className='text-[#4f46D9]'>Exit Impersonate Mode</a>
                                        </div>
                                        }
                                    </div>
                                </div>

                            </div>


                        </div>
                    </>
                </button>
            );
        }
        else {
            return (

                <a href={MicrosoftURL} className="inline-block px-1 group" data-title={'Login'}>
                    Login




                </a>
            );
        }
    }







    private handleShowHide = () => {
        this.setState({
            showhide: (!this.state.showhide)
        });
    }



}
export default Account;