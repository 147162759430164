import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { useIdleTimer } from 'react-idle-timer'
import { LogoutURL,MicrosoftURL } from './objects';
import LoginPage from './pages/LoginPage';
import SessionPopup from "./components/SessionPopup";

export default function IdleManager () {
  const timeout = 15*60*1000;   //in ms (*1000)
  //const timeout = 10*1000;   //in ms (*1000)
  const [remaining, setRemaining] = useState(timeout)
  const [elapsed, setElapsed] = useState(0)
  const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)

  const [showPopup, setShowPopup] = useState(false)

  const handleOnActive = () => {
    setIsIdle(false);
    console.log("setIsIdle", false);
  }
  const handleOnIdle = () => {
    console.log("lastActive",lastActive, typeof lastActive);

    setIsIdle(true);
    setShowPopup(true);
    console.log("setIsIdle", true);
    /*
    if (window.confirm("You are still there")) {
      window.location.href= MicrosoftURL;
    }
    else {
      window.location.href =LogoutURL;
    }
    */
  }

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  //const handleReset = () => reset()
  //const handlePause = () => pause()
  //const handleResume = () => resume()

  useEffect(() => {
    setRemaining(getRemainingTime())
    setLastActive(getLastActiveTime())
    setElapsed(getElapsedTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
      setLastActive(getLastActiveTime())
      setElapsed(getElapsedTime())
    }, 1000)
  }, [])

  return (



     <div className='text-right text-[#FFFFFF]'>

{showPopup && <SessionPopup />}


        {/*<h1>Timeout: {timeout/1000}s</h1>*/}
        {/*<h1>Time Remaining: {remaining}</h1>*/}
        {/*<h1>Time Elapsed: {elapsed}</h1>*/}
        <h1>Last Active: {format(lastActive, 'dd/MM/yyyy HH:mm:ss.SSS')}</h1>
        {/*<h1>Idle: {isIdle.toString()}</h1>*/}
      {/*<div>
        <button onClick={handleReset}>RESET</button>
        <button onClick={handlePause}>PAUSE</button>
        <button onClick={handleResume}>RESUME</button>
      </div>
  */}
    </div>
  )
}
