import axios from 'axios';
import saveAs from 'file-saver';
import * as React from 'react';

interface StudentFormInfoProps {
    text: string;
}

interface StudentFormInfoState {
    //showhide: boolean,
}



class StudentFormInfo extends React.Component<StudentFormInfoProps, StudentFormInfoState> {

    public state: StudentFormInfoState = {
        showhide: false,
    };

    public render() {

        return (

            <button className="inline-block px-1 group float-right" data-title={'Info'}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#F88F2A" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
                <div className="hidden group-hover:block absolute z-10 border-[#DDDDDD] border-[1px] w-[400px] ml-[-370px]" onClick={(event)=>{  event.preventDefault(); return false;}}>
                    <div className="relative bg-white border-[1px] border-[#F88F2A] rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:my-0 sm:max-w-lg sm:w-full">
                        <div className="bg-white">
                            <div className="text-left p-3  " dangerouslySetInnerHTML={{ __html: this.props.text }}>
                            </div>
                        </div>
                    </div>
                </div>
            </button>
        );
    }






/*
    private handleShowHide = () => {
        this.setState({
            showhide: (!this.state.showhide)
        });
    }
*/


}
export default StudentFormInfo;